
import React, { useState, useRef, useEffect } from 'react';
import Notiflix from 'notiflix';

import PostApiCall from '../API/POSTAPI';
import QRCode from 'qrcode';
import QrReader from 'react-qr-reader';

import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';


const VisitorForm = () => {

    const componentRef = useRef();
    const qrRef = useRef(null)
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    const [name, setname] = useState("");
    const [designation, setdesignation] = useState("");
    const [company, setcompany] = useState("");
    const [mobile, setmobile] = useState("");
    const [email, setemail] = useState("");
    const [QRdata, setQRdata] = useState(null);
    const [userId, setuserId] = useState(null)
    const [Print, setPrint] = useState(false)

    const [scanResultFile, setscanResultFile] = useState('')
    const [cameraShow, setcameraShow] = useState(false)



    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })


    // React.useEffect(() => {

    //     if (window.innerWidth > 500) {
    //         window.location.href = "/"
    //     }

    // }, [])

    React.useEffect(() => {
        if (Print) {
            handlePrint()
        }
    }, [Print])




    const visitoradmin = () => {

        Notiflix.Loading.dots();

        PostApiCall.postRequest(
            {
                userid: null,
                firstname: name,
                mobile1: mobile,
                emailid: email,
                designation: designation == "" ? null : designation,
                company: company == "" ? null : company,
                qrdata: null,
                source: "HIDE",
            },
            "update_visitor_admin"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.success("Data Saved Successully");

                    QRCode.toDataURL(`${obj.data[0].visitornumber}`)

                        .then((url) => {

                            setQRdata(url)
                            setuserId(obj.data[0].visitornumber)
                            // console.log(url)

                            PostApiCall.postRequest(
                                {
                                    id: obj.data[0].visitornumber,
                                    email: email,
                                    name: name,
                                    qrdata: url,
                                },
                                "send_mail_visitor_admin"
                            ).then((resultmail) =>
                                resultmail.json().then((obj1) => {
                                    console.log(resultmail)
                                    if (resultmail.status == 200 || resultmail.status == 201) {
                                        // console.log(obj1)
                                        document.querySelector("#popup-btn").click()
                                        Notiflix.Loading.remove();
                                        Notiflix.Notify.success("Mail Send Succesfully.");
                                    } else {
                                        Notiflix.Loading.remove();
                                        Notiflix.Notify.failure(obj1.data);
                                    }
                                })
                            );
                        })
                        .catch((err) => {
                            console.error(err);
                        });

                    //   window.location.Reload();
                } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(obj.data);
                }
            })
        );
    };


    const handleScanFile = (result) => {

        if (result) {
            console.log(result)


            setscanResultFile(result)
        }
    }



    return (
        <>
            <div className='row justify-content-center  m-0'>
                <img className='col-11' src="/assets/images/MainLOGO.png" width="100%" style={{ borderRadius: "7px" }} />
            </div>

            <div className='row justify-content-center m-0 mt-3'>
                <img className='col-11 p-0' src={require("../assets/image/visitorform.jpg")} width="100%" height="200px" style={{ borderRadius: "7px" }} />
            </div>


            <div className='title px-3 mt-3'>

                <h1 className=' mt-1'>Visitor Form</h1>

                <div className="row m-0 pt-3 mt-3">
                    <input
                        type="text"
                        className="form-control mb-3"
                        id="name"
                        placeholder='Enter Name*'
                        value={name}
                        onChange={e => setname(e.target.value)}
                    />

                    <input
                        type="text"
                        className="form-control mb-3"
                        id="designation"
                        placeholder='Enter Designation'
                        value={designation}
                        onChange={e => setdesignation(e.target.value)}
                    />

                    <input
                        type="text"
                        className="form-control mb-3"
                        id="designation"
                        placeholder='Enter Company'
                        value={company}
                        onChange={e => setcompany(e.target.value)}
                    />


                    <input
                        type="text"
                        className="form-control mb-3"
                        id="mobile1"
                        placeholder='Enter Mobile Number*'
                        value={mobile}
                        onChange={(e) => {
                            if (/^[0-9]*$/.test(e.target.value) && e.target.value.length < 11) {
                                setmobile(e.target.value)
                            }
                        }}
                    />

                    <input
                        type="email"
                        className="form-control mb-3"
                        id="emailid"
                        placeholder='Enter Email*'
                        value={email}
                        onChange={e => setemail(e.target.value)}
                    />


                    <div className="col-12 my-3 p-0">
                        <button
                            type="button"
                            className="btn footer-btn col-12"
                            onClick={() => {
                                if (name != "") {
                                    if (mobile != "") {
                                        if (mobile.length == 10) {
                                            if (email != "") {

                                                if (/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
                                                    visitoradmin()
                                                } else {
                                                    Notiflix.Notify.failure("Please Enter Valid Email")
                                                }
                                            } else {
                                                Notiflix.Notify.failure("Enter Your Email");
                                            }
                                        }
                                        else {
                                            Notiflix.Notify.failure("Enter Valid Mobile Number");
                                        }
                                    }
                                    else {
                                        Notiflix.Notify.failure("Enter Your Mobile Number");
                                    }
                                } else {
                                    Notiflix.Notify.failure("Enter Your Name");
                                }
                            }}
                        >
                            Submit
                        </button>
                    </div>






                    {/* {cameraShow &&
                        <QrReader
                            onResult={(result, error) => {
                                if (!!result) {
                                   console.log(result?.text)
                                }

                                if (!!error) {
                                    console.info(error);
                                }
                            }}
                            style={{ width: '100%' }}
                        />

                    } */}
                </div>

            </div>

            <button style={{ display: "none" }} id="popup-btn" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">xfgsfg</button>

            <div className='form-canvas'>
                <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                    <div class="offcanvas-header ">
                        <h5 class="offcanvas-title" id="offcanvasBottomLabel">Visitor Detail</h5>
                        <i class="bi bi-x-circle-fill close-card-btn" data-bs-dismiss="offcanvas" aria-label="Close"></i>
                    </div>

                    <div class="offcanvas-body small" >


                        <div className='text-center p-2 pt-5' ref={componentRef}>

                            <img className='col-8' src="/assets/images/MainLOGO.png" width="100%" style={{ borderRadius: "7px" }} />

                            <img width="80%" src={QRdata} />

                            <p className='text-center mb-3' style={{ fontSize: Print ? "30px" : "16px", fontWeight: "600" }}>{name}</p>
                            <p className='text-center' style={{ fontSize: Print ? "30px" : "16px", }} >Date - {new Date().toLocaleDateString()}</p>
                            <p className='text-center mb-3' style={{ fontSize: Print ? "30px" : "16px" }} >Your Registration No. is {userId} </p>

                        </div>

                        <div className='row justify-content-between m-0 mt-3'>

                            <button className='btn footer-btn col-auto px-4' onClick={() => {
                                // setPrint(true)
                            }}>Print / Download</button>

                            <button className='btn footer-btn col-auto px-4' onClick={() => {
                                window.location.reload()
                            }} data-bs-dismiss="offcanvas" aria-label="Close">Close</button>

                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default VisitorForm