import React from "react";
import { Helmet } from "react-helmet";
import Banner1 from "../assets/banner/banner.jpg";

import "./Gallery.css";
import Footer from "./Footer";
import Header from "./Header";

const Videos = () => {
  return (
    <>
      <Helmet>
        <title>Explore Latest Video Content on Hide India</title>
        <meta name="description"
          content="Discover the newest video content on Hide India. Stay updated with the latest videos on a variety of topics" />
        <meta name="keywords"
          content="Hospitality Interior & Design Expo 2025, Goa Edition , Videos of Goa Edition , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions" />

        {/* Og Tags  */}
        <meta property="og:url" content="https://hideindia.com/videos" />
        <meta property="og:title" content="Explore Latest Video Content on Hide India" />
        <meta property="og:description" content="Discover the newest video content on Hide India. Stay updated with the latest videos on a variety of topics" />
        <meta name="og:site_name" content="Hospitality Interior & Design Expo 2025" />
        <meta property="og:image" content="https://hideindia.com/static/media/banner.8f03bebabd819f34df2b.jpg" />

        {/* Twiter Tags  */}
        <meta property="twitter:url" content="https://hideindia.com/videos" />
        <meta property="twitter:title" content="Explore Latest Video Content on Hide India" />
        <meta property="twitter:description" content="Discover the newest video content on Hide India. Stay updated with the latest videos on a variety of topics" />
        <meta name="twitter:keywords"
          content="Hospitality Interior & Design Expo 2025, Goa Edition , Videos of Goa Edition , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions" />
        <meta property="twitter:image" content="https://hideindia.com/static/media/banner.8f03bebabd819f34df2b.jpg" />
        <link rel="canonical" href="https://hideindia.com/videos" />
      </Helmet>

      <Header />
      <section class="slider">
        <div class="container-fluid p-0">
          <img src={Banner1} alt="" class="bannerimg2 forwebsite" />
          {/* <img src='/assets/images/banner/mobilebanner/banner.jpg' alt='' class="bannerimg2 formobile" /> */}
          <div class="content">
            <div class="bannertext"></div>
          </div>
        </div>
      </section>
      <div class="container-fluid mt-5">
        <div class="title">
          <h1 style={{ marginBottom: "10px" }}>Videos</h1>
        </div>
      </div>
      <div className="conatiner-fluid video-section gallery">
        <div className="row">
          <div className="col-md-6 col-lg-4 mb-4">
            <iframe
              src="https://www.youtube.com/embed/uzq_17ZRsGs"
              title="HIDE INDIA EXPO GOA 2023"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <iframe
              src="https://www.youtube.com/embed/EMB9gorPzUU"
              title="HIDE INDIA EXPO GOA 2023"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <iframe
              src="https://www.youtube.com/embed/DVS425bBUGA"
              title="HIDE INDIA EXPO GOA 2023."
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <iframe
              src="https://www.youtube.com/embed/GV-pEYWkatQ"
              title="HIDE INDIA EXPO GOA 2023"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <iframe
              width="914"
              height="514"
              src="https://www.youtube.com/embed/HBaBuszSQOc"
              title="HIDE INDIA EXPO GOA EDITION 2023"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Videos;
