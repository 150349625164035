import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
export default function Graph({ chartData1 }) {
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        height: 280,
        type: "area",
        stacked: !0,
        toolbar: {
          show: 0,
        },
        zoom: {
          enabled: !0,
        },
      },
      plotOptions: {
        bar: {
          horizontal: !0,
          columnWidth: "10%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: 0,
      },
      xaxis: {
        categories: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",

        ],
      },
      legend: {
        position: "bottom",
        show: 0,
      },
      colors: ["var(--color-theme)", "#f7904c"],
      fill: {
        colors: ["#f7904c", "#ff6600"],
      },
      stroke: {
        curve: "smooth",
      },
      responsive: [
        {
          breakpoint: 1000,
          yaxis: {
            categories: [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",

            ],
          },
          options: {
            plotOptions: {
              bar: {
                horizontal: true,
                columnWidth: "50%",
                endingShape: "rounded",
              },
            },
          },
        },
      ],

    },
    series: [
      {
        name: "series1",
        data: [31, 40, 28, 51, 42, 109, 123, 140, 190],
      },
      {
        name: "series2",
        data: [11, 32, 45, 32, 34, 52, 60, 75, 88],
      },
    ],
  });
  // console.log(chartData1)
  return (
    // <div id="chart">
    //     <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={350} />
    // </div>
    <ReactApexChart
      options={chartData1?.options}
      series={chartData1?.series}
      type="area"
      height={320}
    />
  );
}
