
import React from 'react';
import Banner from '../Comp/Banner';
import Category from '../Comp/Category';
import Footer from '../Comp/Footer';
import Header from '../Comp/Header';
import ShowAbout from '../Comp/ShowAbout';
import ShowHighlight from '../Comp/ShowHighlight';
import ShowOverview from '../Comp/ShowOverview';
import Homecarousel from '../Comp/Homcarousel';
import { Helmet } from 'react-helmet';
import ParallaxSection from '../Comp/parallax';

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Hospitality Interior & Design Expo | India's leading Exhibition Organizer</title>
                <meta name="description"
                    content="The Hospitality Interior & Design Expo 2025 is India's leading event for the designs behind business growth! India's only Exclusive Trade Fair on Furniture, Furnishings, Interiors, Lighting, Design & Surfacing for Hospitality Industry" />
                <meta name="keywords"
                    content="Hospitality Interior & Design Expo 2025, India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions" />

                {/* Og Tags */}
                <meta property="og:url" content="https://hideindia.com/" />
                <meta property="og:title" content="Hospitality Interior & Design Expo | India's leading Exhibition Organizer" />
                <meta property="og:description" content="The Hospitality Interior & Design Expo 2025 is India's leading event for the designs behind business growth! India's only Exclusive Trade Fair on Furniture, Furnishings, Interiors, Lighting, Design & Surfacing for Hospitality Industry" />
                <meta name="og:site_name" content="Hospitality Interior & Design Expo 2025" />
                <meta property="og:image" content="https://hideindia.com/assets/images/banner_new.png.webp" />

                {/* Twiter Tags */}
                <meta property="twitter:url" content="https://hideindia.com/" />
                <meta property="twitter:title" content="Hospitality Interior & Design Expo | India's leading Exhibition Organizer" />
                <meta property="twitter:description" content="The Hospitality Interior & Design Expo 2025 is India's leading event for the designs behind business growth! India's only Exclusive Trade Fair on Furniture, Furnishings, Interiors, Lighting, Design & Surfacing for Hospitality Industry" />
                <meta name="twitter:keywords"
                    content="Hospitality Interior & Design Expo 2025, India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions " />
                <meta property="twitter:image" content="https://hideindia.com/assets/images/banner_new.png.webp" />
                <link rel="canonical" href="https://hideindia.com/" />
            </Helmet>

            <Header />
            <div class="page-wrapper">
                <Banner />
            </div>

            <Category />
            <ShowOverview />
            <ShowAbout />
            <ShowHighlight />
            {/* <ParallaxSection /> */}
            <Homecarousel />

            <Footer />

        </>
    )
}

export default Home