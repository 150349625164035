import moment from "moment";
import Notiflix from "notiflix";
import { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { Link } from "react-router-dom";
import GetApiCall from "../API/GETAPI";
import logo from "../assets/banner/hidelogo.png";
import "./Stats.css";

export default function VisitorList() {
  const [List, setList] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  useEffect(() => {
    GetApiCall.getRequest("GetDirectoryList").then((resultscity) =>
      resultscity.json().then((obj) => {
        if (resultscity.status == 200 || resultscity.status == 201) {
          setList(obj.data);
        }

        Notiflix.Loading.remove();
      })
    );
  }, []);

  return (
    <>
      <div className="d-flex w-100 justify-content-center bg-white shadow-sm pb-1">
        <img
          //   className="col-11"
          src={logo}
          width="87%"
          style={{ borderRadius: "7px", marginTop: "10px" }}
        />
      </div>
      <div className="container-fluid mt-4 mb-5 stats-page visitor-list-page">
        <div className="row">
          <div className="col-6 pe-1">
            <Link
              to="/visitor-dashboard"
              className={
                window.location.pathname == "/visitor-dashboard"
                  ? "top-btn active"
                  : "top-btn"
              }>
              Visitor Count
            </Link>
          </div>
          <div className="col-6 ps-1">
            <Link
              to="/visitor-list"
              className={
                window.location.pathname == "/visitor-list"
                  ? "top-btn active"
                  : "top-btn"
              }>
              Visitor List
            </Link>
          </div>
          <div className="col-12 mt-2">
            <Link
              to="/pre-registered-list"
              className={
                window.location.pathname == "/pre-registered-list"
                  ? "top-btn active"
                  : "top-btn"
              }>
              Pre Registered List
            </Link>
          </div>

          <div className="col-12 mt-4">
            <div className="visitor-list-search-box">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={searchVal}
                onChange={(e) => {
                  setSearchVal(e.target.value);
                }}
              />
              <span>
                <IoSearch />
              </span>
            </div>
          </div>
          {List.length > 0
            ? List?.filter((filter) => {
              if (filter.fld_name.includes(searchVal)) {
                return filter;
              }
              if (filter.fld_mobile.includes(searchVal)) {
                return filter;
              }
              if (filter.fld_company.includes(searchVal)) {
                return filter;
              }
              if (filter.fld_email.includes(searchVal)) {
                return filter;
              }
              if (searchVal == "") {
                return filter;
              }
            })?.sort((a, b) => b.fld_id - a.fld_id).map((data) => {
              return (
                <div className="col-12 mt-3">
                  <div className="visitor-list-card">
                    <div className="d-flex justify-content-between">
                      <p>{data.fld_name}</p>
                      <p>{moment(data.fld_created_on).format("DD-MM-YYYY")}</p>
                    </div>
                    <p>{data.fld_company}</p>

                    <div className="visitor-list-box">
                      <p>+91 {data.fld_mobile}</p>
                      <p>{data.fld_email}</p>
                    </div>
                  </div>
                </div>
              );
            })
            : ""}
        </div>
      </div>
    </>
  );
}
