import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
export default function MultyGraph({ chartData, chartData1, chartData2 }) {
  const [finalChartData, setFinalChartData] = useState({
    options: {
      chart: {
        height: 280,
        type: "area",
        stacked: !0,
        toolbar: {
          show: 0,
        },
        zoom: {
          enabled: !0,
        },
      },
      plotOptions: {
        bar: {
          horizontal: !0,
          columnWidth: "10%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: 0,
      },
      xaxis: {
        categories: ["10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00"],
      },
      legend: {
        position: "bottom",
        show: 0,
      },
      colors: ["#f7914c69", "#fc7f2c", "#ff6600"],
      fill: {
        colors: ["#ffbc90", "#fc7f2c", "#ff6600"],
      },
      stroke: {
        curve: "smooth",
      },
      responsive: [
        {
          breakpoint: 1000,
          yaxis: {
            categories: ["10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00"],
          },
          options: {
            plotOptions: {
              bar: {
                horizontal: true,
                columnWidth: "50%",
                endingShape: "rounded",
              },
            },
          },
        },
      ],
    },
    series: [
      {
        name: "series",
        data: [31, 40, 28, 51, 42, 109, 123, 140, 190],
      },
      {
        name: "series",
        data: [11, 32, 45, 32, 34, 52, 60, 75, 88],
      },
      {
        name: "series",
        data: [31, 40, 28, 51, 42, 52, 60, 75, 88],
      },
    ],
  });
  useEffect(() => {
    let dat = []
    dat.push(chartData?.series[0])
    dat.push(chartData1?.series[0])
    dat.push(chartData2?.series[0])
    setFinalChartData((prev) => ({
      ...prev,
      series: [dat],
    }))
  }, [chartData, chartData1, chartData2])
  return (
    // <div id="chart">
    //     <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={350} />
    // </div>
    <div>
      <ReactApexChart
        options={finalChartData?.options}
        series={finalChartData?.series[0]}
        type="area"
        height={320}
      />
    </div>
  );
}
