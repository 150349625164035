import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function BodyClass() {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;

    // Clear previous classes
    document.body.className = "";

    // Add different classes based on routes
    if (path === "/previewcatalogue") {
      document.body.classList.add("mobile-version-width");
    } else if (path === "/visitorform") {
      document.body.classList.add("mobile-version-width");
    } else if (path === "/qr-scan") {
      document.body.classList.add("mobile-version-width");
    } else if (path === "/my-ticket") {
      document.body.classList.add("mobile-version-width");
    } else if (path === "/seminar-calendar") {
      document.body.classList.add("mobile-version-width");
    } else if (path === "/dashboard") {
      document.body.classList.add("mobile-version-width");
    } else if (path === "/directorylist") {
      document.body.classList.add("mobile-version-width");
    } else if (path === "/catalogqr") {
      document.body.classList.add("mobile-version-width");
    } else if (path === "/stats") {
      document.body.classList.add("mobile-version-width");
    } else if (path === "/visitor-dashboard") {
      document.body.classList.add("mobile-version-width");
    } else if (path === "/visitor-list") {
      document.body.classList.add("mobile-version-width");
    } else if (path === "/pre-registered-list") {
      document.body.classList.add("mobile-version-width");
    } else if (path === "/visit") {
      document.body.classList.add("mobile-version-width");
    } else {
      document.body.classList.add("default-page");
    }

    // Clean up when the component unmounts or route changes
    return () => {
      document.body.className = ""; // Reset body class when route changes
    };
  }, [location]);

  return null; // This component only handles side effects
}

export default BodyClass;
