import { Input } from "antd";
import { useHistory } from "react-router-dom";
import "./Stats.css";
import LoginInput from "./LoginInput";
export default function Stats() {
  let history = useHistory();
  const onChange = (text) => {
    // console.log('onChange:', text);
    if (text == 2812) {
      history.push('/visitor-dashboard')
    }
  };
  const sharedProps = {
    onChange,
  };
  return (
    <div className="start-page">
      <img
        src={require("../assets/image/stats-banner.jpeg")}
        className="img-fluid"
      />
      <div className="enter-pin-box">
        {/* <Input.OTP length={4} formatter={(str) => str.toUpperCase()} {...sharedProps} /> */}
        <LoginInput length={4} onComplete={onChange} />

      </div>
    </div>
  );
}
