import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import logo from "../assets/banner/hidelogo.png";
import Image1 from "../assets/banner/image1.png.webp";
import Image2 from "../assets/banner/image2.png.webp";
import Image3 from "../assets/banner/image3.png.webp";
import Image4 from "../assets/banner/image4.png.webp";
import Image5 from "../assets/banner/image5.png.webp";
import EventImg from "../assets/image/event-img.jpeg";
import BottomNavbar from "./BottomNavbar";
import "./Showdirectory.css";
import Notiflix from "notiflix";
import PostApiCall from "../API/POSTAPI";
export default function Dashboard() {
  const [list ,setList] = useState([])
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  // React.useEffect(() => {
  //   if (window.innerWidth > 500) {
  //     window.location.href = "/";
  //   }
  // }, []);

  useEffect(() => {
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "Get_Directory_List"
    ).then((resultscity) =>
      resultscity.json().then((obj) => {
        if (resultscity.status == 200 || resultscity.status == 201) {
          console.log(obj.data);
          setList(obj.data);
        }

        Notiflix.Loading.remove();
      })
    );
  }, []);
  return (
    <>
      <div className="d-flex w-100 justify-content-center bg-white">
        <img
          //   className="col-11"
          src={logo}
          width="87%"
          style={{ borderRadius: "7px", marginTop: "10px" }}
        />
      </div>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Swiper
                slidesPerView={"auto"}
                spaceBetween={10}
                pagination={{ clickable: true }}
                loop={true}
                autoplay={{
                  delay: 1000,
                  disableOnInteraction: false,
                }}
                style={{ paddingLeft: "70px", marginTop: "10px" }}>
                <SwiperSlide>
                  <div
                    class="mb-3"
                    style={{ boxShadow: "0 2px 5px rgb(162 169 204 / 24%)" }}>
                    <img
                      src={Image1}
                      width="100%"
                      style={{ borderRadius: "7px" }}
                    />
                    <h6 className="swiper-text">Furniture Zone</h6>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div
                    class="mb-3"
                    style={{ boxShadow: "0 2px 5px rgb(162 169 204 / 24%)" }}>
                    <img
                      src={Image2}
                      width="100%"
                      style={{ borderRadius: "7px" }}
                    />
                    <h6 className="swiper-text">Furnishings Zone</h6>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div
                    class="mb-3"
                    style={{ boxShadow: "0 2px 5px rgb(162 169 204 / 24%)" }}>
                    <img
                      src={Image3}
                      width="100%"
                      style={{ borderRadius: "7px" }}
                    />
                    <h6 className="swiper-text">Al Fresco Zone</h6>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div
                    class="mb-3"
                    style={{ boxShadow: "0 2px 5px rgb(162 169 204 / 24%)" }}>
                    <img
                      src={Image4}
                      width="100%"
                      style={{ borderRadius: "7px" }}
                    />
                    <h6 className="swiper-text">Lighting Zone</h6>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div
                    class="mb-3"
                    style={{ boxShadow: "0 2px 5px rgb(162 169 204 / 24%)" }}>
                    <img
                      src={Image5}
                      width="100%"
                      style={{ borderRadius: "7px" }}
                    />
                    <h6 className="swiper-text">Surfacing Zone</h6>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      <div class="container-fluid">
        <div class="row justify-content-between">
          <div class="col-12 ">
            <div class="title" id="overview">
              <h2>Show Overview</h2>
            </div>
            <p class="overviewtext mt-3">
              The Hospitality Interior & Design Expo 2025 is India's leading
              event for the designs behind business growth! This unmissable
              event unites the industry’s leading creatives with a plethora of
              solutions they need to transform their business,customer loyalty
              and ultimately increase their profits. All under one roof,
              visitors can gain invaluable insights from the sector's leading
              visionaries as they reveal their secrets to success, whilst some
              of the biggest brands will be on hand showcasing the very best
              products the industry has to offer!
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid mb-5 pb-5 overflow-hidden dashboard-page">
        <div className="row flex-column-reverse flex-lg-row">
          <div className="col-lg-12">
            <div class="title text-start mt-4">
              <h1 style={{ marginBottom: "10px" }}>Events</h1>
              <img
                src={EventImg}
                className="img-fluid mb-3"
                alt="MR & MS HIDE 2025"
              />
              {/* <div className="mt-3">
                <p>
                  JD School of Design is thrilled to host the official Model
                  Hunt for MR & MS HIDE 2025 as an education partner for the
                  HIDE India exhibition!
                </p>
                <p>
                  We invite aspiring models to participate in this exciting
                  competition.
                </p>
              </div> */}
            </div>
          </div>
        </div>
        {/* <div className="row my-3">
          <div className="col-lg-7">
            <p>
              <b>
                To register, please complete the form below with your accurate
                details.
              </b>
            </p>
            <p>
              Join us on this fantastic opportunity to shine on the runway and
              kickstart your modelling career!
            </p>
            <p>
              To enter, submit a 2-minute audition video showcasing your
              introduction and runway walk.
            </p>
            <p className="subheading">
              <b>HOW TO SUBMIT:</b>
            </p>
            <p>
              DM your video to{" "}
              <a
                href="https://www.instagram.com/jdinstitute_goa/?img_index=1"
                target="_blank"
                className="text-dark">
                <b>@jdinstitute_goa</b>
              </a>{" "}
              and email the same to
              <a
                href="mailto:divya.a@jdinstitute.edu.in"
                target="_blank"
                className="text-dark">
                {" "}
                <b> divya.a@jdinstitute.edu.in</b>
              </a>
            </p>
            <div className=" stall_button-top-nav seminar-button position-static mt-2 mb-2">
              <a href="https://forms.gle/hBUbu2kHgyikYPXs9" target="_blank">
                Register for Model Hunt
              </a>
            </div>
            <p>
              <b>Deadline:</b> 9th September by 1 PM
            </p>
            <p className="subheading">
              <b>WHAT'S NEXT?</b>
            </p>
            <p>
              Shortlisted candidates will be invited for a Grooming session
              followed by Trials and Fittings.
            </p>
            <ul className="capital-letter p-2">
              <li>
                <b>ATTRACTIVE PRIZES AWAIT!</b>
              </li>
              <li>
                <b>CASH PRIZES</b>
              </li>
              <li>
                <b>CERTIFICATES AND A CHANCE TO WEAR DESIGNER GARMENTS</b>
              </li>
              <li>
                <b>OPPORTUNITY TO MODEL FOR FUTURE EVENTS AT JD INSTITUTE</b>
              </li>
            </ul>

            <p className="subheading">
              <b>Eligibility Requirements:</b>
            </p>
            <ul className="events-dot">
              <li>1. Must be a college student</li>
              <li>
                2. Should be following our official page{" "}
                <a
                  href="mailto:divya.a@jdinstitute.edu.in"
                  target="_blank"
                  className="text-dark">
                  <b>@jdinstitute_goa</b>
                </a>
              </li>
            </ul>

            <p>
              For more details, DM us at{" "}
              <a
                href="mailto:divya.a@jdinstitute.edu.in"
                target="_blank"
                className="text-dark">
                <b>@jdinstitute_goa</b>
              </a>{" "}
              or contact us at{" "}
              <a href="tel:+919021900400.">
                <b className="text-dark">+91-90219 00400.</b>
              </a>
            </p>
            <div className="stall_button stall_button-top-nav seminar-button position-static mt-2 mb-2">
              <a href="https://forms.gle/hBUbu2kHgyikYPXs9" target="_blank">
                Register for Model Hunt
              </a>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="event-image mt-lg-0 mt-3">
              <img
                src={event}
                alt="About us"
                class="img-fluid aboutusimg pe-xl-2"
              />
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-6">
            <h6 className="footerpromotorsfont" style={{ color: "#F7904C" }}>
              Knowledge Partner
            </h6>
            <div class="footerlogosdiv footer-logos my-3">
              <img
                className="lastlogowidth "
                src="../assets/images/revisedhideindia2023logo/JD-School-logo.jpg"
                alt="JD School logo"
              />
            </div>
          </div>
          <div className="col-6">
            <h6 className="footerpromotorsfont" style={{ color: "#F7904C" }}>
              Hospitality Partner
            </h6>
            <div class="footerlogosdiv footer-logos my-3">
              <img
                className="lastlogowidth"
                src="../assets/images/revisedhideindia2023logo/IIHM-LOGO.png"
                alt="IIHM LOGO"
              />
            </div>
          </div>
        </div>
        <hr class="contacthr w-100" />

        <div className="row">
          <div className="col-6">
            <h6 className="footerpromotorsfont" style={{ color: "#F7904C" }}>
              Promoters
            </h6>
            <div class="footerlogosdiv my-3">
              <img
                className="lastlogowidth"
                src="../assets/images/introductionlogo.png"
                alt="HIDE logo"
              />
            </div>
          </div>

          <div className="col-6">
            <h6 className="footerpromotorsfont" style={{ color: "#F7904C" }}>
              Web Partner
            </h6>
            <div class="footerlogosdiv my-3">
              <a href="http://globaltrendz.com/" target="_blank">
                <img
                  className="lastlogowidth"
                  src="/assets/images/GTlogo.png"
                  alt="Global Trendz"
                />
              </a>
            </div>
          </div>
        </div>
        <hr class="contacthr w-100" />
        <div className="row">
          <div className="col-md-6 col-sm-6 col-12">
            <h5 className="footerpromotorsfont" style={{ color: "#F7904C" }}>
              Internet Partner
            </h5>
            <div class="footerlogosdiv my-3">
              <img
                src="/assets/images/companylogo/EXPL-Logo.png"
                alt="EXPL Logo"
                className="lastlogowidth"
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-12">
          <hr class="contacthr w-100 d-lg-none" />
            <h5 className="footerpromotorsfont" style={{ color: "#F7904C" }}>
              Outdoor Partner
            </h5>
            <div class="footerlogosdiv my-3">
              <img
                src="/assets/images/companylogo/AK Apple Logo.jpg"
                alt="AK Apple Logo"
                className="w-50"
              />
            </div>
          </div>
        </div>
        <hr class="contacthr w-100" />
        <div className="row">
          <div className="co-12">
            <h5 className="footerpromotorsfont" style={{ color: "#F7904C" }}>
              Digital Partner
            </h5>
          </div>
          <div className="col-md-6 col-sm-6 col-12">
            <div class="footerlogosdiv my-3">
              <img
                src="/assets/images/companylogo/Flashlab Logo.png"
                alt="Flashlab Logo"
                className="w-50"
              />
            </div>
          </div>
        </div>
        <hr class="contacthr w-100" />
        <div className="row">
          <div className="co-12">
            <h6 className="footerpromotorsfont" style={{ color: "#F7904C" }}>
              Supported By
            </h6>
          </div>
          <div className="col-6">
            <div class="footerlogosdiv my-3">
              <img
                src="../assets/images/companylogo/indianinterior.png"
                alt="Indian Interior"
                className="lastlogowidth"
              />
            </div>
          </div>
          <div className="col-6">
            <div class="footerlogosdiv my-3">
              <img
                src="../assets/images/companylogo/iiid.jpeg"
                alt="IIID"
                className="lastlogowidth"
              />
            </div>
          </div>

          {/* <div className="col-6">
            <div class="footerlogosdiv my-3">
              <img
                src="../assets/images/revisedhideindia2023logo/MSME-Logo.jpeg"
                alt="MSME"
                className="lastlogowidth"
              />
            </div>
          </div> */}
          <div className="col-6">
            <div class="footerlogosdiv my-3">
              <img
                src="../assets/images/companylogo/ghralogo.png"
                alt="GHRA"
                className="lastlogowidth bg-white"
              />
            </div>
          </div>
        </div>
      </div>

      <BottomNavbar />
    </>
  );
}
