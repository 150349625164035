import React from "react";
import Footerlogo from "../assets/banner/hidelogo.png";
import { FaFacebookSquare, FaWhatsapp } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";

const Footer = () => {
  return (
    <footer style={{ backgroundColor: "black" }} id="contactus">
      <div class="container-fluid footercontainer">
        <div class="row">
          <div class="col-md-6 me-auto">
            {/* <h5 style={{ color: "#F7904C" }} class="contactushead">
              In Association with
            </h5> */}
            <div class="footerlogosdiv mt-3 ml-0 hide-logo">
              <img src={Footerlogo} alt="HIDE logo" class=" bg-white" />
              {/* <img
                src="/assets/images/ghralogo.png"
                alt="HIDE logo"
                class="footerlogo2 mx-4"
              /> */}
            </div>

            {/* <div class="footerlogosdiv my-3">
              <img
                src="/assets/images/afmtlogo.jpeg"
                class="commonfootericonsizeafmt pe-3"
              />
              <img
                src="/assets/images/newlogofooter.png"
                class="pe-3 ps-0 mx-0 py-2 commonfootericonsize "
              />
            </div> */}

            <hr class="contacthr" style={{ width: "70% " }} />
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <h5
                  className="footerpromotorsfont"
                  style={{ color: "#F7904C" }}>
                  Knowledge Partner
                </h5>
                <div class="footerlogosdiv footer-logos my-3">
                  <img
                    className="lastlogowidth "
                    src="/assets/images/revisedhideindia2023logo/JD-School-logo.jpg"
                    alt="JD School logo"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <h5
                  className="footerpromotorsfont"
                  style={{ color: "#F7904C" }}>
                  Hospitality Partner
                </h5>
                <div class="footerlogosdiv footer-logos my-3">
                  <img
                    className="lastlogowidth"
                    src="/assets/images/revisedhideindia2023logo/IIHM-LOGO.png"
                    alt="IIHM LOGO"
                  />
                </div>
              </div>
            </div>
            <hr class="contacthr" style={{ width: "70% " }} />

            <div className="row">
              {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <h5
                  className="footerpromotorsfont"
                  style={{ color: "#F7904C" }}>
                  Promotion Partner
                </h5>
                <div class="footerlogosdiv my-3">
                  <img
                    src="/assets/images/launchingstories.png"
                    style={{width: '100%',height: '87px'}}
                    className="lastlogowidth"
                  />
                </div>
              </div> */}

              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <h5
                  className="footerpromotorsfont"
                  style={{ color: "#F7904C" }}>
                  Promoters
                </h5>
                <div class="footerlogosdiv my-3">
                  <img
                    className="lastlogowidth"
                    src="/assets/images/introductionlogo.png"
                    alt="HIDE logo"
                  />
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <h5
                  className="footerpromotorsfont"
                  style={{ color: "#F7904C" }}>
                  Web Partner
                </h5>
                <div class="footerlogosdiv my-3">
                  <a href="http://globaltrendz.com/" target="_blank">
                    <img
                      className="lastlogowidth"
                      src="/assets/images/GTlogo.png"
                      alt="Global Trendz"
                    />
                  </a>
                </div>
              </div>
            </div>
            <hr class="contacthr" style={{ width: "70% " }} />
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <h5
                  className="footerpromotorsfont"
                  style={{ color: "#F7904C" }}>
                  Internet Partner
                </h5>
                <div class="footerlogosdiv my-3">
                  <img
                    src="/assets/images/companylogo/EXPL-Logo.png"
                    alt="EXPL Logo"
                    className="lastlogowidth"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <h5
                  className="footerpromotorsfont"
                  style={{ color: "#F7904C" }}>
                  Outdoor Partner
                </h5>
                <div class="footerlogosdiv my-3">
                  <img
                    src="/assets/images/companylogo/AK Apple Logo.jpg"
                    alt="AK Apple Logo"
                    className="lastlogowidth"
                  />
                </div>
              </div>
            </div>
            <hr class="contacthr" style={{ width: "70% " }} />
            <div className="row">
              <div className="co-12">
                <h5
                  className="footerpromotorsfont"
                  style={{ color: "#F7904C" }}>
                  Digital Partner
                </h5>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="footerlogosdiv my-3">
                  <img
                    src="/assets/images/companylogo/Flashlab Logo.png"
                    alt="Flashlab Logo"
                    className="lastlogowidth bg-white"
                  />
                </div>
              </div>
            </div>
            <hr class="contacthr" style={{ width: "70% " }} />
            <div className="row">
              <div className="co-12">
                <h5
                  className="footerpromotorsfont"
                  style={{ color: "#F7904C" }}>
                  Supported By
                </h5>
              </div>
              {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="footerlogosdiv my-3">
                  <img
                    src="/assets/images/companylogo/credai.png"
                    alt="Credai"
                    className="lastlogowidth"
                  />
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="footerlogosdiv my-3">
                  <img
                    src="/assets/images/companylogo/gar.png"
                    alt="GAR"
                    className="lastlogowidth"
                  />
                </div>
              </div> */}

              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="footerlogosdiv my-3">
                  <img
                    src="/assets/images/companylogo/indianinterior.png"
                    alt="Indian Interior"
                    className="lastlogowidth"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 me-lg-4">
                <div class="footerlogosdiv my-3">
                  <img
                    src="/assets/images/companylogo/iiid.jpeg"
                    alt="IIID"
                    className="lastlogowidth"
                  />
                </div>
              </div>

              {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="footerlogosdiv my-3">
                  <img
                    src="/assets/images/revisedhideindia2023logo/MSME-Logo.jpeg"
                    alt="MSME"
                    className="lastlogowidth"
                  />
                </div>
              </div> */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="footerlogosdiv my-3">
                  <img
                    src="/assets/images/companylogo/ghralogo.png"
                    alt="GHRA"
                    className="lastlogowidth bg-white"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 ">
            <h5 style={{ color: "#F7904C" }} class="contactushead">
              Event Venue Expo 2025{" "}
            </h5>
            <p>
              <b>
                26<sup>th</sup>, 27<sup>th</sup>, 28<sup>th</sup> September 2025{" "}
                <br /> Dr. Shyam Prasad Mukherjee Stadium, Panjim, Goa.
              </b>
              <br />
              India's only Exclusive Trade Fair on Furniture, Furnishings,
              Interiors, Lighting,
              <br /> Design & Surfacing for Hospitality Industry
            </p>
            <hr class="contacthr" />

            <div class="contactus-text">
              <h5 style={{ color: "#F7904C" }} class="contactushead">
                For Layout Plan, Booth Rates & Participation Please Contact{" "}
              </h5>
              <p style={{ marginTop: "2px" }}>
                <b>Introduction Trade Shows Pvt Ltd</b>
                <br />
                115, Morya Estate, New Link Road, Andheri West,
                <br />
                Mumbai - 400 053. India
                <br /> Email :{" "}
                <a
                  href="mailto:sales@infairs.co.in"
                  target="_blank"
                  style={{ color: "#fff" }}>
                  sales@infairs.co.in
                </a>
                <br />
                Website :{" "}
                <a
                  href="http://www.infairs.co.in/"
                  target="_blank"
                  style={{ color: "#fff" }}>
                  www.infairs.co.in
                </a>
                <br />
                <hr class="contacthr" />
                <h5 style={{ color: "#F7904C" }} class="contactushead">
                  Contact Number
                </h5>
                <span>
                  <strong> Jivesh Sachdev : </strong>
                </span>
                <a href="tel:9322228089" style={{ color: "#fff" }}>
                  +91 93222 28089
                </a>
                <br />
                <span>
                  <strong> Renit Daniel : </strong>
                </span>
                <a href="tel:9840093858" style={{ color: "#fff" }}>
                  +91 98400 93858
                </a>
                {/* <br />
                <span>
                  <strong> Aakansha : </strong>
                </span>
                <a href="tel:7880038782" style={{ color: "#fff" }}>
                  +91 78800 38782
                </a> */}
              </p>
            </div>
          </div>
        </div>

        {/* whatsapp floating icon */}

        <hr style={{ paddingRight: "100px" }} />

        <div class="row forwebsite pb-3">
          <div class="col-md-auto me-auto">
            <p>
              Design{" "}
              <a
                href="http://globaltrendz.com"
                target="_blank"
                style={{ color: "#fff" }}>
                Global Trendz
              </a>
            </p>
          </div>
          <div class="col-md-auto">
            <p style={{ textAlign: "right" }}>
              Copyright © {new Date().getFullYear()}{" "}
              <a href="https://hideindia.com" style={{ color: "#fff" }}>
                Hospitality Interior & Design Expo
                <script>document.write(/\d{4}/.exec(Date())[0])</script>
              </a>{" "}
              . All Rights Reserved.
            </p>
          </div>
        </div>

        <div class="row formobile pb-3">
          <div class="col-md-12">
            <p style={{ textAlign: "center" }}>
              Copyright © {new Date().getFullYear()}{" "}
              <a href="https://hideindia.com" style={{ color: "#fff" }}>
                {" "}
                Hospitality Interior & Design Expo
                <script>document.write(/\d{4}/.exec(Date())[0])</script>
              </a>
              <br /> All Rights Reserved.
            </p>
          </div>

          <div class="col-md-12">
            <p style={{ textAlign: "center" }}>
              Design{" "}
              <a
                href="http://globaltrendz.com"
                target="_blank"
                style={{ color: "#fff" }}>
                Global Trendz
              </a>
            </p>
          </div>
        </div>
      </div>

      <div class="floating_btn call_icon">
        <a href="https://wa.me/+919322228089" class="whatsapp-btn">
          <FaWhatsapp />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
