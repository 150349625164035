
import React from 'react'
import { Helmet } from 'react-helmet'
// import Category from '../Comp/Category'
import Footer from '../../Comp/Footer'
import Header from '../../Comp/Header'

const About = () => {
    return (
        <>

            <Helmet>
                <title>Travel & Stay | Hospitality Interior & Design Expo 2025 | HIDE India  </title>
                <meta name="description"
                    content="Book your Flight, Hotel, Visa with Gotripon, Corporate Office - 52 Sai Infotech, Ground Floor, Patel Chowk, R B Mehta Marg, Ghatkopar East, Land Mark: Opposite Station" />
                <meta name="keywords"
                    content="Hospitality Interior & Design Expo 2025, Travel & Stay, Visa with Gotripon  , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions" />

                {/* Og Tags */}
                <meta property="og:url" content="https://hideindia.com/travel" />
                <meta property="og:title" content="Travel & Stay | Hospitality Interior & Design Expo 2025 | HIDE India  " />
                <meta property="og:description" content="Book your Flight, Hotel, Visa with Gotripon, Corporate Office - 52 Sai Infotech, Ground Floor,, Patel Chowk, R B Mehta Marg, Ghatkopar East, Land Mark: Opposite Station" />
                <meta name="og:site_name" content="Hospitality Interior & Design Expo 2025" />
                <meta property="og:image" content="https://hideindia.com/assets/images/inner-banner/travel&stay.webp" />

                {/* Twiter Tags */}
                <meta property="twitter:url" content="https://hideindia.com/travel" />
                <meta property="twitter:title" content="Travel & Stay | Hospitality Interior & Design Expo 2025 | HIDE India  " />
                <meta property="twitter:description" content="Book your Flight, Hotel, Visa with Gotripon, Corporate Office - 52 Sai Infotech, Ground Floor,, Patel Chowk, R B Mehta Marg, Ghatkopar East, Land Mark: Opposite Station" />
                <meta name="twitter:keywords"
                    content="Hospitality Interior & Design Expo 2025, Travel & Stay, Visa with Gotripon  , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions" />
                <meta property="twitter:image" content="https://hideindia.com/assets/images/inner-banner/travel&stay.webp" />
                <link rel="canonical" href="https://hideindia.com/travel" />
            </Helmet>

            <Header />

            <section class="slider">
                <div class="container-fluid p-0">
                    <img src='/assets/images/inner-banner/travel&stay.webp' alt='' class="bannerimg2 forwebsite" />
                    <img src='/assets/images/banner/mobilebanner/travel&stay.webp' alt='' class="bannerimg2 formobile" />
                    <div class="content">
                        <div class="bannertext">

                        </div>

                    </div>
                </div>
            </section>



            <section class="aboutusection">
                <div class="container-fluid">
                    <div class="title">
                        <h1 style={{ marginBottom: "10px" }} >Travel & Stay</h1>

                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <p style={{ marginTop: "1em" }} >
                                <b></b><br />For Your Flight, Hotel, Visa :
                                <br></br>

                                <b><i>Gotripon</i></b> <br />
                                <a href='mailto: talk@gotripon.com' style={{ color: '#000', fontSize: '18px' }}>talk@gotripon.com</a>

                                <br></br>
                                <b><i>Mobile</i></b> <br />
                                <a href='tel: 8422006501' style={{ color: '#000', fontSize: '18px' }}>+91 84220 06501</a>

                                <br></br>
                                <b><i>Accounts</i></b> <br />
                                <a href='mailto: accounts@gotripon.com' style={{ color: '#000', fontSize: '18px' }}>accounts@gotripon.com</a>


                            </p>


                        </div>

                        <div class="col-md-5">
                            <p style={{ marginTop: "1em" }} >
                                <b></b><br />Corporate Office
                                <br></br>

                                <a href='' style={{ color: '#000', fontSize: '18px' }}>52 Sai Infotech, Ground Floor,<br></br> Patel Chowk, R B Mehta Marg, Ghatkopar East </a>
                                <br></br>
                                <p>Land Mark: Opposite Station</p>


                            </p>


                        </div>

                        <div className='col-lg-5'>
                            <img src="./assets/images/travel.webp" alt="About us" class="img-fluid aboutusimg pe-xl-2" />

                        </div>


                    </div>
                </div>


            </section>

            <Footer />
        </>
    )
}

export default About