import moment from "moment";
import Notiflix from "notiflix";
import { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { Link } from "react-router-dom";
import PostApiCall from "../API/POSTAPI";
import logo from "../assets/banner/hidelogo.png";
import "./Stats.css";

export default function PreRegisteredList() {
    const [List, setList] = useState([]);
    const [searchVal, setSearchVal] = useState("");
    useEffect(() => {
        PostApiCall.postRequest(
            {
                WhereClause: "",
            },
            "Get_Visitor"
        ).then((resultscity) =>
            resultscity.json().then((obj) => {
                if (resultscity.status == 200 || resultscity.status == 201) {
                    setList(obj.data);
                }

                Notiflix.Loading.remove();
            })
        );
    }, []);

    return (
        <>
            <div className="d-flex w-100 justify-content-center bg-white shadow-sm pb-1">
                <img
                    //   className="col-11"
                    src={logo}
                    width="87%"
                    style={{ borderRadius: "7px", marginTop: "10px" }}
                />
            </div>
            <div className="container-fluid mt-4 mb-5 stats-page visitor-list-page">
                <div className="row">
                    <div className="col-6 pe-1">
                        <Link
                            to="/visitor-dashboard"
                            className={
                                window.location.pathname == "/visitor-dashboard"
                                    ? "top-btn active"
                                    : "top-btn"
                            }>
                            Visitor Count
                        </Link>
                    </div>
                    <div className="col-6 ps-1">
                        <Link
                            to="/visitor-list"
                            className={
                                window.location.pathname == "/visitor-list"
                                    ? "top-btn active"
                                    : "top-btn"
                            }>
                            Visitor List
                        </Link>
                    </div>
                    <div className="col-12 mt-2">
                        <Link
                            to="/pre-registered-list"
                            className={
                                window.location.pathname == "/pre-registered-list"
                                    ? "top-btn active"
                                    : "top-btn"
                            }>
                            Pre Registered List
                        </Link>
                    </div>

                    <div className="col-12 mt-4">
                        <div className="visitor-list-search-box">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                                value={searchVal}
                                onChange={(e) => {
                                    setSearchVal(e.target.value);
                                }}
                            />
                            <span>
                                <IoSearch />
                            </span>
                        </div>
                    </div>
                    {List.length > 0
                        ? List?.filter((filter) => {
                            if (filter.FLD_FIRST_NAME?.includes(searchVal)) {
                                return filter;
                            }
                            if (filter.FLD_LAST_NAME?.includes(searchVal)) {
                                return filter;
                            }
                            if (filter.FLD_MOBILE1?.includes(searchVal)) {
                                return filter;
                            }
                            if (filter.FLD_COMPANY?.includes(searchVal)) {
                                return filter;
                            }
                            if (filter.FLD_EMAIL_ID?.includes(searchVal)) {
                                return filter;
                            }
                            if (searchVal == "") {
                                return filter;
                            }
                        })?.sort((a, b) => b.FLD_USER_ID - a.FLD_USER_ID)?.map((data) => {
                            return (
                                <div className="col-12 mt-3">
                                    <div className="visitor-list-card">
                                        <div className="d-flex justify-content-between">
                                            <p>{data.FLD_FIRST_NAME}</p>
                                            <p>{moment(data.FLD_CREATED_ON).format("D-MM-YYYY")}</p>
                                        </div>
                                        <p>{data.FLD_COMPANY}</p>
                                        <div className="visitor-list-box">
                                            <p>+91 {data.FLD_MOBILE1}</p>
                                            <p>{data.FLD_EMAIL_ID}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                        : ""}
                </div>
            </div>
        </>
    );
}
