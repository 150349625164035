
import React from 'react'
import { MdDoubleArrow } from "react-icons/md";

const BooknowPopup = () => {
    return (
        <div class="container-fluid phn-view-below-banner-bookbtn">
            <div className='row'>
                <div className='col-12'>
                    <ul className='d-flex justify-content-center'>
                        <li>
                            {/* <li className="book-stall-btn-bottom-animation">
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span> */}
                            <button className='border-0 stall_button stall_button-banner-phnview bg-white py-sm-3 px-5 py-2 fs-sm-4 fs-6' data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ border: '1px solid #000' }}>Book Now <MdDoubleArrow class="mx-2" /></button>
                        </li>
                    </ul>
                </div>
            </div>




            {/* <!-- Modal --> */}
            <div class="modal fade bottom-popup-booknow" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p>
                                If you are interested in exhibiting at HIDE India 2025, please fill out the form given below.
                            </p>
                            <div className="lax1 phn-view-below-banner-bookbtn">
                                <ul className='justify-content-center mt-4 pt-2'>
                                    <li className=" heart mb-4 mb-sm-5">
                                        <a href='/exhibitor-registration' target="_blank" className='stall_button stall_button-banner-phnview bg-white px-sm-4 py-sm-3 px-2 py-2 fs-sm-4 fs-6 border-0' style={{ border: '1px solid #000' }}>Book Your Stall</a>
                                    </li>
                                    <li className=" heart mb-4">

                                        <a href='/visitor-registration' target="_blank" className='stall_button stall_button-banner-phnview bg-white px-sm-4 py-sm-3 px-2 py-2 fs-sm-4 fs-6 border-0' style={{ border: '1px solid #000' }}>Visitor Registration</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BooknowPopup;