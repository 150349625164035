
import React from 'react';
import Category from '../Comp/Category';
import Footer from '../Comp/Footer'
import Header from '../Comp/Header';
import Video from '../assets/video/surfacing.mp4';
import ReactPlayer from "react-player/lazy";
import { Helmet } from 'react-helmet';

const Surfacingzone = () => {
    return (
        <>
            <Helmet>
                <title>Surfacing Zone | Exhibition Organizer | Hide India Expo 2025 </title>
                <meta name="description"
                    content="Having well-designed, well-fitted, and stain-resistant floors, walls, worktops, and ceilings that adhere to health and safety guidelines is more often than not overlooked." />
                <meta name="keywords"
                    content="Hospitality Interior & Design Expo 2025,Book Your Exhibition Stall, Exhibition Stall  Creative Directors , Business Development Directors, Architects, Heads of Marketing , Graphic Designers , Brand Manager , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions" />

                {/* Og Tags */}
                <meta property="og:url" content="https://hideindia.com/Surfacing-zone" />
                <meta property="og:title" content="Surfacing Zone | Exhibition Organizer | Hide India Expo 2025 " />
                <meta property="og:description" content="Having well-designed, well-fitted, and stain-resistant floors, walls, worktops, and ceilings that adhere to health and safety guidelines is more often than not overlooked." />
                <meta name="og:site_name" content="Hospitality Interior & Design Expo 2025" />
                <meta property="og:image" content="https://hideindia.com/assets/images/inner-banner/Surfacing_zone.webp" />

                {/* Twiter Tags */}
                <meta property="twitter:url" content="https://hideindia.com/Surfacing-zone" />
                <meta property="twitter:title" content="Surfacing Zone | Exhibition Organizer | Hide India Expo 2025 " />
                <meta property="twitter:description" content="Having well-designed, well-fitted, and stain-resistant floors, walls, worktops, and ceilings that adhere to health and safety guidelines is more often than not overlooked." />
                <meta name="twitter:keywords"
                    content="Hospitality Interior & Design Expo 2025,Book Your Exhibition Stall, Exhibition Stall  Creative Directors , Business Development Directors, Architects, Heads of Marketing , Graphic Designers , Brand Manager , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions " />
                <meta property="twitter:image" content="https://hideindia.com/assets/images/inner-banner/Surfacing_zone.webp" />
                <link rel="canonical" href="https://hideindia.com/Surfacing-zone" />
            </Helmet>

            <Header />
            <section class="slider">
                <div class="container-fluid p-0">
                    <img src='/assets/images/inner-banner/Surfacing_zone.webp' alt='' class="bannerimg2 forwebsite" />
                    <img src='assets/images/banner/mobilebanner/surfacingzone.png.webp' alt='' class="bannerimg2 formobile" />
                    <div class="content">
                        <div class="bannertext" id="banner_text_inner">
                            <h1>Surfacing Zone</h1>
                            <p class="bannerp">The place to source the highest-quality surfacing materials
                                from around <br /> the world to enhance the look and feel of your
                                establishment. Surfacing <br /> Zone will exhibit the latest trends and designs of
                                products for Walls, Ceilings,<br />floorings for
                                the Hospitality industry at HIDE INDIA 2025
                            </p>
                        </div>

                    </div>
                </div>
            </section>


            <Category />


            <section class="furnituresection">
                <div class="container-fluid">
                    <div class="title">
                        <h1 className='mb-1'>Surfacing Zone</h1>
                    </div>
                    <div class="row">
                        <div class="col-md-7">
                            <p className='mt-2'>
                                The place to source the highest-quality surfacing materials from around the world to enhance the
                                look and feel of your
                                establishment.
                                Having a well-designed, well-fitted and stain-resistant floors, walls, worktops and ceilings
                                that adhere to health and
                                safety guidelines is more often than not overlooked. Your choice of surfaces will play a part in
                                your customer’s overall
                                experience of your business!
                                That goes to show that beautifully designed surfaces will help set the mood, open up the space
                                within your establishment
                                and will ultimately attract more customers to your business. Therefore we want to make sure that
                                your surfaces don't let
                                you down.
                                In this zone we will only be housing the most contemporary and hard-wearing surfacing materials
                                available. From
                                beautiful, easy-to-clean ceramic tiles, to opulent oak-wood flooring and even super stylish
                                marble worktops, you’ll be
                                able to find the perfect solution for your business right here!
                                <b> <i>Surfacing Zone</i></b> will exhibit the latest trends and designs of products for Walls,
                                Ceilings, floorings for the Hospitality
                                industry at <b>HIDE 2025</b>
                            </p>
                        </div>
                        <div class="col-md-5">
                            <ReactPlayer
                                url={Video}
                                playing
                                loop={true}
                                autoplay
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </div>
                </div>
            </section>




            <Footer />

        </>
    )
}

export default Surfacingzone