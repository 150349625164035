import React, { Component } from "react";
import Notiflix from "notiflix";
import PostApiCall from "../../API/POSTAPI";
import GetApiCall from "../../API/GETAPI";
import QRCode from "qrcode";

export default class VisitorRegistrationIframe extends Component {
  constructor() {
    super();
    this.state = {
      qrdata: "",

      CountryData: [],

      EmailRegex:
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

      NumRegex: /^[0-9]*$/,

      viewOTPbox: false,
      OTP: null,
      OTPInput: null,
      emailid: "",
      nextbtn: false,

      firstname: "",
      middlename: "",
      lastname: "",
      designation: "",
      company: "",
      emailid1: "",
      Phonecode: "",
      mobile1: "",

      addressline1: "",
      addressline2: "",
      addressline3: "",
      pincode: "",
      city: "",
      state: "",
      country: "",

      typeofbusiness: [],

      productinterestedin: [],
      otherinterestproducts: "",

      acknowledgeTick: false,

      userid: null,

      usertype: null,
      salt: null,
      password: null,

      CountryData: [],
      CountryId: null,

      StateData: [],
      StateId: null,

      CityData: [],
      CityId: null,
    };
  }

  generateQR = async (text) => {
    try {
      return await QRCode.toDataURL(text);
    } catch (err) {
      console.error(err);
    }
  };

  componentDidMount() {
    GetApiCall.getRequest("Get_Country").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          // console.log(obj.data)
          this.setState({ CountryData: obj.data });
        }
      })
    );
  }

  onCountryChange() {
    PostApiCall.postRequest(
      {
        countryid: this.state.CountryId,
      },
      "Get_State"
    ).then((results) =>
      results.json().then((objstate) => {
        if (results.status == 200 || results.status == 201) {
          if (objstate.data.length != 0) {
            this.setState({ StateData: objstate.data });
          }
        }
      })
    );
  }

  onStateChange() {
    PostApiCall.postRequest(
      {
        stateid: this.state.StateId,
      },
      "Get_City"
    ).then((resultscity) =>
      resultscity.json().then((objcity) => {
        if (resultscity.status == 200 || resultscity.status == 201) {
          if (objcity.data.length != 0) {
            this.setState({
              CityId: objcity.data[0].value,
              CityData: objcity.data,
              City: objcity.data[0].label,
            });
          }
        }
      })
    );
  }

  SendOTP() {
    if (this.state.emailid != "") {
      if (this.state.EmailRegex.test(this.state.emailid)) {
        var otp = Math.floor(100000 + Math.random() * 900000);
        this.setState({ OTP: otp });

        Notiflix.Loading.dots("Please wait...");

        PostApiCall.postRequest(
          {
            OTP: otp,
            email: this.state.emailid,
          },
          "Mailer_Send_OTP_Ifex"
        ).then((result) =>
          result.json().then((obj) => {
            if (result.status == 200 || result.status == 201) {
              Notiflix.Loading.remove();
              Notiflix.Notify.success(
                "Your verification OTP is successfully sent to your email address.."
              );
              this.setState({ viewOTPbox: true });
            } else {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure(obj.data);
            }
          })
        );
      } else {
        Notiflix.Notify.failure("Please enter valid email address.");
      }
    } else {
      Notiflix.Notify.failure("Please enter your email address.");
    }
  }

  addVisitor = async () => {
    PostApiCall.postRequest(
      {
        userid: this.state.userid,
        emailid: this.state.emailid,
        usertype: this.state.usertype,
        salt: this.state.salt,
        password: this.state.password,
        invitedby: null,
        invitedbyname: null,
        title: null,
        firstname: this.state.firstname,
        middlename: null,
        lastname: this.state.lastname,
        designation: this.state.designation,
        company: this.state.company,
        emailid1: null,

        countrycode: this.state.Phonecode,
        mobile1: this.state.mobile1,

        addressline1: this.state.addressline1,
        addressline2: this.state.addressline2,
        addressline3: this.state.addressline3,

        pincode: this.state.pincode,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        telnumber: null,
        whatsappno: null,
        email2: null,
        website: null,
        typeofbusiness: this.state.typeofbusiness.toString(),
        productinterestedin:
          this.state.productinterestedin.length != 0
            ? this.state.productinterestedin.toString()
            : this.state.otherinterestproducts,
        purposevisit: null,
        photo: null,
        covidvaccine: null,
        createdby: this.state.createdby,
        updateddate: this.state.updateddate,
        updatedby: this.state.updatedby,
        source: "IFEX",
      },
      "update_visitor"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          QRCode.toDataURL(`${obj.data[0].visitornumber}`)
            .then((url) => {
              PostApiCall.postRequest(
                {
                  id: obj.data[0].visitornumber,
                  email: this.state.emailid,
                  name: `${this.state.firstname} ${this.state.lastname}`,
                  qrdata: url,
                },
                "Send_Ticket_Visitor_Mailer_ifex"
              ).then((results) =>
                results.json().then((obj) => {
                  if (results.status == 200 || results.status == 201) {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.success(
                      "Thank you for Submitting your Visitor Details Successfully. We have sent the Visitor Badge to you on your registered email id."
                    );
                    window.location.reload();
                  } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(obj.data);
                  }
                })
              );
            })
            .catch((err) => {
              console.error(err);
            });

          //     Notiflix.Loading.remove();
          //     Notiflix.Notify.success("Data is successfully Added.");
          //     window.location.href = '/'
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };

  // feedtypeofbusiness({ e, cat }) {

  //     if (e.target.checked) {

  //         this.setState({ typeofbusiness: { ...this.state.typeofbusiness, [cat]: (this.state.typeofbusiness?.[cat] == undefined ? [e.target.value] : [...this.state.typeofbusiness[cat], e.target.value]) } }, () => console.log(this.state.typeofbusiness))

  //     } else {
  //         this.setState({ typeofbusiness: { ...this.state.typeofbusiness, [cat]: this.state.typeofbusiness?.[cat]?.filter(val => val != e.target.value) } }, () => {
  //             console.log(this.state.typeofbusiness)
  //         })

  //     }
  // }

  feedtypeofbusiness({ e, cat }) {
    if (e.target.checked) {
      this.setState({
        typeofbusiness: [...this.state.typeofbusiness, e.target.value],
      });
    } else {
      this.setState({
        typeofbusiness: this.state.typeofbusiness.filter(
          (val) => val != e.target.value
        ),
      });
    }
  }

  feedproductofinterest({ e, cat }) {
    if (e.target.checked) {
      this.setState({
        productinterestedin: [
          ...this.state.productinterestedin,
          e.target.value,
        ],
      });
    } else {
      this.setState({
        productinterestedin: this.state.productinterestedin.filter(
          (val) => val != e.target.value
        ),
      });
    }
  }

  render() {
    return (
      <>
        <div className="container-fluid mt-4 ">
          <div class="title">
            {/* <h1 style={{ margin: "10px 0px" }} >Visitor's Registration Form </h1> */}
          </div>

          <ul class="nav nav-tabs mt-4 d-none" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="step1-tab"
                data-bs-toggle="tab"
                data-bs-target="#step1"
                type="button"
                role="tab"
                aria-controls="step1"
                aria-selected="true"
              >
                Step 1
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="step2-tab"
                data-bs-toggle="tab"
                data-bs-target="#Step2"
                type="button"
                role="tab"
                aria-controls="Step2"
                aria-selected="false"
              >
                Step 2
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="step3-tab"
                data-bs-toggle="tab"
                data-bs-target="#Step3"
                type="button"
                role="tab"
                aria-controls="Step3"
                aria-selected="false"
              >
                Step 3
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="step4-tab"
                data-bs-toggle="tab"
                data-bs-target="#Step4"
                type="button"
                role="tab"
                aria-controls="Step4"
                aria-selected="false"
              >
                Step 4
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="step5-tab"
                data-bs-toggle="tab"
                data-bs-target="#Step5"
                type="button"
                role="tab"
                aria-controls="Step5"
                aria-selected="false"
              >
                Step 5
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="step6-tab"
                data-bs-toggle="tab"
                data-bs-target="#Step6"
                type="button"
                role="tab"
                aria-controls="Step6"
                aria-selected="false"
              >
                Step 6
              </button>
            </li>
          </ul>

          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="step1"
              role="tabpanel"
              aria-labelledby="step1-tab"
            >
              <div className="row m-0 justify-content-center m-2 border p-3 rounded-3">
                <div
                  className="row m-0 py-2 rounded-2 text-white"
                  style={{ backgroundColor: "#BC945E" }}
                >
                  <h4 className="col-auto">Step 1 - Visitor's Registration</h4>
                </div>

                <div
                  className="col-md-6 mt-3 pb-3 rounded-3"
                  style={{ border: "2px dotted gray" }}
                >
                  <h4 className="text-center mt-2">REGISTER WITH OTP</h4>

                  <div class="my-3  ">
                    <label for="otpmail" class="form-label">
                      Email Id
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={this.state.emailid}
                      onChange={(e) => {
                        this.setState({ emailid: e.target.value });
                      }}
                      id="otpmail"
                      disabled={this.state.viewOTPbox}
                    />
                  </div>

                  {this.state.viewOTPbox && (
                    <div className="row m-0 mb-3 align-items-end">
                      <div class="col-sm-4 ps-0">
                        <label for="formGroupExampleInput" class="form-label">
                          Enter OTP
                        </label>
                        <input
                          type="text"
                          value={this.state.OTPInput}
                          onChange={(e) =>
                            this.setState({ OTPInput: e.target.value })
                          }
                          class="form-control"
                          id="formGroupExampleInput"
                        />
                      </div>

                      <button
                        className="col-auto btn btn-danger mt-2 mt-md-0 download"
                        style={{ background: "#BC945E" }}
                        onClick={() => {
                          if (this.state.OTP == this.state.OTPInput) {
                            Notiflix.Notify.success("OTP Match Successfully");
                            document.querySelector("#step2-tab").click();
                          } else {
                            Notiflix.Notify.failure(
                              "Wrong OTP Enter. Try again"
                            );
                            this.setState({
                              emailid: "",
                              OTP: null,
                              OTPInput: null,
                              viewOTPbox: false,
                            });
                          }
                        }}
                      >
                        {" "}
                        Confirm
                      </button>
                    </div>
                  )}

                  {!this.state.viewOTPbox && (
                    <button
                      className="btn btn-danger download"
                      style={{ background: "#BC945E" }}
                      onClick={this.SendOTP.bind(this)}
                    >
                      Send OTP
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="Step2"
              role="tabpanel"
              aria-labelledby="step2-tab"
            >
              <div className="row m-0 m-2 border p-3 rounded-3">
                <div
                  className="row m-0 py-2 rounded-2 text-white mb-4"
                  style={{ backgroundColor: "#BC945E" }}
                >
                  <h4 className="col-auto">Step 2 - Visitor's Registration</h4>
                </div>

                <div class=" col-md-4 mb-3 ">
                  <label for="formGroupExampleInput" class="form-label mb-0">
                    First Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="formGroupExampleInput"
                    value={this.state.firstname}
                    onChange={(text) => {
                      this.setState({
                        firstname: text.target.value,
                      });
                    }}
                  />
                </div>

                <div class=" col-md-4 mb-3 ">
                  <label for="formGroupExampleInput" class="form-label mb-0">
                    Last Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="formGroupExampleInput"
                    value={this.state.lastname}
                    onChange={(text) => {
                      this.setState({
                        lastname: text.target.value,
                      });
                    }}
                  />
                </div>

                <div class="col-md-4 mb-3 ">
                  <label for="inputState" class="form-label mb-0">
                    Designation <span className="text-danger">*</span>
                  </label>
                  <select
                    id="inputState"
                    class="form-select"
                    onChange={(e) =>
                      this.setState({ designation: e.target.value })
                    }
                  >
                    <option value="">Choose...</option>
                    <option value="Assistant General Manager">
                      Assistant General Manager
                    </option>
                    <option value="Assistant Manager">Assistant Manager</option>
                    <option value="CEO">CEO</option>
                    <option value="COO">COO</option>
                    <option value="CFO">CFO</option>
                    <option value="CMO">CMO</option>
                    <option value="CFO">CTO</option>
                    <option value="President">President</option>
                    <option value="Manager">Manager</option>
                    <option value="Marketing Manager">Marketing Manager</option>
                    <option value="Sr. Manager">Sr. Manager</option>
                    <option value="Deputy Manager">Deputy Manager</option>

                    <option value="General Manager">General Manager</option>
                    <option value="Chairman">Chairman</option>
                    <option value="Sales Executive">Sales Executive</option>
                    <option value="Team Leader">Team Leader</option>
                    <option value="Vice President">Vice President</option>
                    <option value="Administrative Officer">
                      Administrative Officer
                    </option>
                    <option value="Accountant">Accountant</option>
                    <option value="Managing Director">Managing Director</option>
                    <option value="Director">Director</option>
                    <option value="Proprietor">Proprietor</option>
                    <option value="Individual">Individual</option>
                  </select>
                </div>

                <div class=" col-md-4 mb-3 ">
                  <label for="formGroupExampleInput" class="form-label mb-0">
                    Email Id <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="formGroupExampleInput"
                    value={this.state.emailid}
                    onChange={(text) => {
                      this.setState({
                        emailid: text.target.value,
                      });
                    }}
                  />
                </div>

                {/* <div class=" col-md-4 mb-3 ">
                                        <label for="formGroupExampleInput" class="form-label mb-0">Mobile No. <span className='text-danger'>*</span></label>
                                        <div className='row m-0'>
                                            <div className='col-4 ps-0'>
                                                <input type="text" class="form-control" id="formGroupExampleInput" disabled value="+91" />
                                            </div>
                                            <div className='col-8 pe-0'>
                                                <input type="text" class="form-control" value={this.state.mobile1} id="formGroupExampleInput" onChange={mobile => {
                                                    if (this.state.NumRegex.test(mobile.target.value) && mobile.target.value.length <= 10) {

                                                        this.setState({ mobile1: mobile.target.value })
                                                    }
                                                }} />
                                            </div>
                                        </div>
                                    </div> */}

                <div class=" col-md-4 mb-3 ">
                  <div className="row m-0">
                    <div className="col-4 ps-0">
                      <label
                        for="formGroupExampleInput"
                        class="form-label mb-0"
                      >
                        Country Code <span className="text-danger">*</span>{" "}
                      </label>
                      <select
                        id="inputState"
                        class="form-select"
                        onChange={(e) =>
                          this.setState({ Phonecode: e.target.value })
                        }
                      >
                        <option value="">Choose...</option>
                        {this.state.CountryData.map((val) => (
                          <option value={val.Phone_Code}>
                            {val.Phone_Code} - {val.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-8 pe-0">
                      <label
                        for="formGroupExampleInput"
                        class="form-label mb-0"
                      >
                        Mobile No. <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        value={this.state.mobile1}
                        id="formGroupExampleInput"
                        onChange={(mobile) => {
                          if (this.state.NumRegex.test(mobile.target.value)) {
                            this.setState({ mobile1: mobile.target.value });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div class=" col-md-4 mb-3 ">
                  <label for="formGroupExampleInput" class="form-label mb-0">
                    Company{" "}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="formGroupExampleInput"
                    value={this.state.company}
                    onChange={(text) => {
                      this.setState({ company: text.target.value });
                    }}
                  />
                </div>

                <hr />

                <div className="col-12 d-flex justify-content-end">
                  <button
                    className=" col-auto btn btn-danger download"
                    style={{ background: "#BC945E" }}
                    onClick={() => {
                      if (this.state.firstname != "") {
                        if (this.state.lastname != "") {
                          if (this.state.designation != "") {
                            if (this.state.emailid != "") {
                              if (
                                this.state.EmailRegex.test(this.state.emailid)
                              ) {
                                if (this.state.Phonecode != "") {
                                  if (this.state.mobile1 != "") {
                                    document
                                      .querySelector("#step3-tab")
                                      .click();
                                  } else {
                                    Notiflix.Notify.failure(
                                      "Please Enter Mobile Number"
                                    );
                                  }
                                } else {
                                  Notiflix.Notify.failure(
                                    "Please Enter Country Code"
                                  );
                                }
                              } else {
                                Notiflix.Notify.failure(
                                  "Please Enter valid Email Address"
                                );
                              }
                            } else {
                              Notiflix.Notify.failure(
                                "Please Enter Email Address"
                              );
                            }
                          } else {
                            Notiflix.Notify.failure(
                              "Please Select Designation"
                            );
                          }
                        } else {
                          Notiflix.Notify.failure("Please Enter Last Name");
                        }
                      } else {
                        Notiflix.Notify.failure("Please Enter First Name");
                      }
                    }}
                  >
                    Next{" "}
                  </button>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="Step3"
              role="tabpanel"
              aria-labelledby="step3-tab"
            >
              <div className="row m-0 m-2 border p-3 rounded-3">
                <div
                  className="row m-0 py-2 rounded-2 text-white mb-3"
                  style={{ backgroundColor: "#BC945E" }}
                >
                  <h4 className="col-auto">Step 3 - Visitor's Registration</h4>
                </div>

                <div class=" col-md-4 mb-3 ">
                  <label for="formGroupExampleInput" class="form-label mb-0">
                    Address Line 1 <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="formGroupExampleInput"
                    value={this.state.addressline1}
                    onChange={(text) => {
                      this.setState({ addressline1: text.target.value });
                    }}
                    placeholder="SHOP NO./ FLAT NO./ HOUSE NO."
                  />
                </div>

                <div class=" col-md-4 mb-3 ">
                  <label for="formGroupExampleInput" class="form-label mb-0">
                    Address Line 2{" "}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="formGroupExampleInput"
                    value={this.state.addressline2}
                    onChange={(text) => {
                      this.setState({ addressline2: text.target.value });
                    }}
                    placeholder="STREET/AREA/LOCALITY"
                  />
                </div>

                {/* <div class=" col-md-4 mb-3 ">
                                    <label for="formGroupExampleInput" class="form-label mb-0">Address Line 3 </label>
                                    <input type="text" class="form-control" id="formGroupExampleInput" value={this.state.addressline3} onChange={(text) => {
                                        this.setState({ addressline3: text.target.value });
                                    }} placeholder='Landmark' />
                                </div> */}

                <div class=" col-md-4 mb-3 ">
                  <label for="formGroupExampleInput" class="form-label mb-0">
                    Country<span className="text-danger">*</span>{" "}
                  </label>
                  <select
                    id="inputState"
                    class="form-select"
                    onChange={(e) =>
                      this.setState(
                        {
                          country: e.target.value.split(",")[1],
                          CountryId: e.target.value.split(",")[0],
                          city: "",
                          CityData: [],
                          CityId: null,
                          StateId: null,
                          StateData: [],
                          state: "",
                        },
                        () => this.onCountryChange()
                      )
                    }
                  >
                    <option>Choose...</option>
                    {this.state.CountryData.map((val) => (
                      <option value={`${val.value},${val.label}`}>
                        {val.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div class=" col-md-4 mb-3 ">
                  <label for="formGroupExampleInput" class="form-label mb-0">
                    State<span className="text-danger">*</span>{" "}
                  </label>
                  <select
                    id="inputState"
                    class="form-select"
                    onChange={(e) =>
                      this.setState(
                        {
                          state: e.target.value.split(",")[1],
                          StateId: e.target.value.split(",")[0],
                          city: "",
                          CityData: [],
                          CityId: null,
                        },
                        () => this.onStateChange()
                      )
                    }
                  >
                    <option>Choose...</option>
                    {this.state.StateData.map((val) => (
                      <option value={`${val.value},${val.label}`}>
                        {val.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div class=" col-md-4 mb-3 ">
                  <label for="formGroupExampleInput" class="form-label mb-0">
                    City<span className="text-danger">*</span>{" "}
                  </label>
                  <select
                    id="inputState"
                    class="form-select"
                    onChange={(e) =>
                      this.setState({
                        city: e.target.value.split(",")[1],
                        CityId: e.target.value.split(",")[0],
                      })
                    }
                  >
                    <option>Choose...</option>
                    {this.state.CityData.map((val) => (
                      <option value={`${val.value},${val.label}`}>
                        {val.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div class=" col-md-4 mb-3 ">
                  <label for="formGroupExampleInput" class="form-label mb-0">
                    Pin Code <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    value={this.state.pincode}
                    onChange={(e) => {
                      if (this.state.NumRegex.test(e.target.value)) {
                        this.setState({ pincode: e.target.value });
                      }
                    }}
                    id="formGroupExampleInput"
                  />
                </div>

                <div className="col-12 mt-3">
                  <p className="fw-bold">Note:</p>

                  <ul style={{ fontSize: "13px" }}>
                    <li>
                      1. You will recieve your Registration No. and QR code via
                      E-mail, which confirms your registration.
                    </li>

                    <li>
                      2. Carry your Registration no. or QR code on your mobile
                      or in a print format to the trade show.
                    </li>

                    <li>
                      3. Present your Registration no. or scan your QR code at
                      the Pre-registered visitor's desk at India Furniture Expo
                      2025 to get your badge.
                    </li>

                    <li>
                      4. Please note that once you register your email address,
                      it is not changeable at the venue. In case you want to use
                      a different email address or mobile number after
                      registration, you need to register again.
                    </li>
                  </ul>
                </div>

                <hr />

                <div className="col-12 d-flex justify-content-end">
                  <button
                    className=" col-auto btn btn-danger download me-3"
                    style={{ background: "#BC945E" }}
                    onClick={() => document.querySelector("#step2-tab").click()}
                  >
                    Previous{" "}
                  </button>
                  <button
                    className=" col-auto btn btn-danger download"
                    style={{ background: "#BC945E" }}
                    onClick={() => {
                      if (this.state.addressline1 != "") {
                        if (this.state.country != "") {
                          if (this.state.state != "") {
                            if (this.state.city != "") {
                              if (this.state.pincode != "") {
                                document.querySelector("#step4-tab").click();
                              } else {
                                Notiflix.Notify.failure(`Please Enter Pincode`);
                              }
                            } else {
                              Notiflix.Notify.failure("Please Enter City");
                            }
                          } else {
                            Notiflix.Notify.failure("Please Enter State");
                          }
                        } else {
                          Notiflix.Notify.failure("Please Choose Country");
                        }
                      } else {
                        Notiflix.Notify.failure("Please Enter address line 1");
                      }
                    }}
                  >
                    Next{" "}
                  </button>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="Step4"
              role="tabpanel"
              aria-labelledby="step4-tab"
            >
              <div className="row  m-0 m-2 border p-3 rounded-3">
                <div
                  className="row m-0 py-2 rounded-2 text-white mb-3"
                  style={{ backgroundColor: "#BC945E" }}
                >
                  <h4 className="col-auto">Step 4 - Visitor's Registration</h4>
                </div>

                <h5>
                  A. Type(s) of your Business{" "}
                  <span className="text-danger">
                    * (Please tick Two Major Business)
                  </span>
                </h5>

                <div className=" row col-12 mt-3  p-0">
                  <div className="col-md-4 mb-3">
                    <p className="fw-bold">Retailer</p>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        value="Department Store"
                        id="reatil-department-store"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "retailer" })
                        }
                      />
                      <label
                        class="form-check-label"
                        for="reatil-department-store"
                      >
                        {" "}
                        Department Store{" "}
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        value="Hyper Market"
                        id="retail-hyper-mart"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "retailer" })
                        }
                      />
                      <label class="form-check-label" for="retail-hyper-mart">
                        Hyper Market
                      </label>
                    </div>
                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        value="Discount Store"
                        id="retail-discount-store"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "retailer" })
                        }
                      />
                      <label
                        class="form-check-label"
                        for="retail-discount-store"
                      >
                        Discount Store
                      </label>
                    </div>
                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        value="Traditional Stores"
                        id="retail-traditional"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "retailer" })
                        }
                      />
                      <label class="form-check-label" for="retail-traditional">
                        Traditional Stores
                      </label>
                    </div>
                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        value="Boutique"
                        id="retail-boutique"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "retailer" })
                        }
                      />
                      <label class="form-check-label" for="retail-boutique">
                        Boutique
                      </label>
                    </div>
                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        value=""
                        id="retail-cash-carry"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "retailer" })
                        }
                      />
                      <label class="form-check-label" for="retail-cash-carry">
                        Cash and Carry
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p className="fw-bold">Trade Representative</p>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        value="Sales Agent"
                        id="trade-sales-agent"
                        onChange={(e) =>
                          this.feedtypeofbusiness({
                            e,
                            cat: "Trade Representative",
                          })
                        }
                      />
                      <label class="form-check-label" for="trade-sales-agent">
                        Sales Agent{" "}
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        value="Brand Representative"
                        id="trade-brand"
                        onChange={(e) =>
                          this.feedtypeofbusiness({
                            e,
                            cat: "Trade Representative",
                          })
                        }
                      />
                      <label class="form-check-label" for="trade-brand">
                        Brand Representative
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        value="Importer"
                        id="trade-importer"
                        onChange={(e) =>
                          this.feedtypeofbusiness({
                            e,
                            cat: "Trade Representative",
                          })
                        }
                      />
                      <label class="form-check-label" for="trade-importer">
                        Importer
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        value="Wholesaler"
                        id="trade-whole"
                        onChange={(e) =>
                          this.feedtypeofbusiness({
                            e,
                            cat: "Trade Representative",
                          })
                        }
                      />
                      <label class="form-check-label" for="trade-whole">
                        Wholesaler
                      </label>
                    </div>
                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        value="Distributor"
                        id="flexCheckDefault"
                        onChange={(e) =>
                          this.feedtypeofbusiness({
                            e,
                            cat: "Trade Representative",
                          })
                        }
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Distributor
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p className="fw-bold">Bulk Buyers</p>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "Bulk Buyers" })
                        }
                        value="Buying Houses"
                        id="bulk-buying-houses"
                      />
                      <label class="form-check-label" for="bulk-buying-houses">
                        Buying Houses
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "Bulk Buyers" })
                        }
                        value="Institutional Buyers"
                        id="bulk-institutional-buyers"
                      />
                      <label
                        class="form-check-label"
                        for="bulk-institutional-buyers"
                      >
                        Institutional Buyers
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p className="fw-bold">Professional</p>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "Professional" })
                        }
                        value="Architect"
                        id="Professional-architect"
                      />
                      <label
                        class="form-check-label"
                        for="Professional-architect"
                      >
                        Architect{" "}
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "Professional" })
                        }
                        value="Interior Designer"
                        id="Professional-Interior"
                      />
                      <label
                        class="form-check-label"
                        for="Professional-Interior"
                      >
                        Interior Designer
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "Professional" })
                        }
                        value="Hospitality Owner"
                        id="Professional-Hospitality"
                      />
                      <label
                        class="form-check-label"
                        for="Professional-Hospitality"
                      >
                        Hospitality Owner
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "Professional" })
                        }
                        value="Design Consultant"
                        id="Professional-Consultant"
                      />
                      <label
                        class="form-check-label"
                        for="Professional-Consultant"
                      >
                        Design Consultant
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "Professional" })
                        }
                        value="Brand Manager"
                        id="Professional-Brand"
                      />
                      <label class="form-check-label" for="Professional-Brand">
                        Brand Manager
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "Professional" })
                        }
                        value="Purchase Manager"
                        id="Professional-Purchase"
                      />
                      <label
                        class="form-check-label"
                        for="Professional-Purchase"
                      >
                        Purchase Manager
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "Professional" })
                        }
                        value="General Manager (Hotel)"
                        id="Professional-General"
                      />
                      <label
                        class="form-check-label"
                        for="Professional-General"
                      >
                        General Manager (Hotel)
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "Professional" })
                        }
                        value="Builder"
                        id="Professional-Builder"
                      />
                      <label
                        class="form-check-label"
                        for="Professional-Builder"
                      >
                        Builder
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "Professional" })
                        }
                        value="Home Owner"
                        id="Professional-Home"
                      />
                      <label class="form-check-label" for="Professional-Home">
                        Home Owner
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p className="fw-bold">E-Commerce</p>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "E-Commerce" })
                        }
                        value="Online Retailer"
                        id="E-Commerce-online-retail"
                      />
                      <label
                        class="form-check-label"
                        for="E-Commerce-online-retail"
                      >
                        Online Retailer{" "}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p className="fw-bold">Industry & Trade</p>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedtypeofbusiness({
                            e,
                            cat: "Industry & Trade",
                          })
                        }
                        value="Manufacturer"
                        id="Trade-Manufacturer"
                      />
                      <label class="form-check-label" for="Trade-Manufacturer">
                        Manufacturer{" "}
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedtypeofbusiness({
                            e,
                            cat: "Industry & Trade",
                          })
                        }
                        value="Brand Owner"
                        id="Trade-Brand-owner"
                      />
                      <label class="form-check-label" for="Trade-Brand-owner">
                        Brand Owner
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedtypeofbusiness({
                            e,
                            cat: "Industry & Trade",
                          })
                        }
                        value="Exporter"
                        id="Trade-Exporter"
                      />
                      <label class="form-check-label" for="Trade-Exporter">
                        Exporter
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p className="fw-bold">Others</p>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "Others" })
                        }
                        value="Service Provider"
                        id="Others-Service-Provider"
                      />
                      <label
                        class="form-check-label"
                        for="Others-Service-Provider"
                      >
                        Service Provider{" "}
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "Others" })
                        }
                        value="Trade Association"
                        id="Others-Trade-Association"
                      />
                      <label
                        class="form-check-label"
                        for="Others-Trade-Association"
                      >
                        Trade Association
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "Others" })
                        }
                        value="Government"
                        id="Others-Government"
                      />
                      <label class="form-check-label" for="Others-Government">
                        Government
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedtypeofbusiness({ e, cat: "Others" })
                        }
                        value="Miscellaneous"
                        id="Others-Miscellaneous"
                      />
                      <label
                        class="form-check-label"
                        for="Others-Miscellaneous"
                      >
                        Miscellaneous
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-12 d-flex justify-content-end">
                  <button
                    className=" col-auto btn btn-danger download me-3"
                    style={{ background: "#BC945E" }}
                    onClick={() => document.querySelector("#step3-tab").click()}
                  >
                    Previous{" "}
                  </button>
                  <button
                    className=" col-auto btn btn-danger download"
                    style={{ background: "#BC945E" }}
                    onClick={() => {
                      if (this.state.typeofbusiness.length > 1) {
                        document.querySelector("#step5-tab").click();
                      } else {
                        Notiflix.Notify.failure(
                          "Please Select 2 Major Business"
                        );
                      }
                    }}
                  >
                    Next{" "}
                  </button>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="Step5"
              role="tabpanel"
              aria-labelledby="step5-tab"
            >
              <div className="row  m-0 m-2 border p-3 rounded-3">
                <div
                  className="row m-0 py-2 rounded-2 text-white mb-3"
                  style={{ backgroundColor: "#BC945E" }}
                >
                  <h4 className="col-auto">Step 5 - Visitor's Registration</h4>
                </div>

                <h5>
                  B. Products of your Interest{" "}
                  <span className="text-danger">*</span>
                </h5>

                <div className=" row col-12 mt-3  p-0">
                  <div className="col-md-4 mb-3">
                    <p className="fw-bold">Home Decor</p>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({ e, cat: "Home Decor" })
                        }
                        value="Walls"
                        id="Decor-Walls"
                      />
                      <label class="form-check-label" for="Decor-Walls">
                        Walls{" "}
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({ e, cat: "Home Decor" })
                        }
                        value="Floor"
                        id="Decor-Floor"
                      />
                      <label class="form-check-label" for="Decor-Floor">
                        Floor
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({ e, cat: "Home Decor" })
                        }
                        value="Ceiling"
                        id="Decor-Ceiling"
                      />
                      <label class="form-check-label" for="Decor-Ceiling">
                        Ceiling
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({ e, cat: "Home Decor" })
                        }
                        value="Lifestyle"
                        id="Decor-Lifestyle"
                      />
                      <label class="form-check-label" for="Decor-Lifestyle">
                        Lifestyle
                      </label>
                    </div>
                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({ e, cat: "Home Decor" })
                        }
                        value="Windows"
                        id="Decor-Windows"
                      />
                      <label class="form-check-label" for="Decor-Windows">
                        Windows{" "}
                      </label>
                    </div>
                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({ e, cat: "Home Decor" })
                        }
                        value="Lights"
                        id="Decor-Lights"
                      />
                      <label class="form-check-label" for="Decor-Lights">
                        Lights{" "}
                      </label>
                    </div>
                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({ e, cat: "Home Decor" })
                        }
                        value="Accessories"
                        id="Decor-Accessories"
                      />
                      <label class="form-check-label" for="Decor-Accessories">
                        Accessories
                      </label>
                    </div>
                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({ e, cat: "Home Decor" })
                        }
                        value="Handicrafts"
                        id="Decor-Handicrafts"
                      />
                      <label class="form-check-label" for="Decor-Handicrafts">
                        Handicrafts
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p className="fw-bold">Home Furniture</p>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({
                            e,
                            cat: "Home Furniture",
                          })
                        }
                        value="Living & Dining Furniture"
                        id="Furniture-Dining-Furniture"
                      />
                      <label
                        class="form-check-label"
                        for="Furniture-Dining-Furniture"
                      >
                        Living & Dining Furniture{" "}
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({
                            e,
                            cat: "Home Furniture",
                          })
                        }
                        value="Bed Room Furniture"
                        id="Furniture-Bed-Room-Furniture"
                      />
                      <label
                        class="form-check-label"
                        for="Furniture-Bed-Room-Furniture"
                      >
                        Bed Room Furniture
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({
                            e,
                            cat: "Home Furniture",
                          })
                        }
                        value="Kitchen Furniture"
                        id="Furniture-Kitchen-Furniture"
                      />
                      <label
                        class="form-check-label"
                        for="Furniture-Kitchen-Furniture"
                      >
                        Kitchen Furniture
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({
                            e,
                            cat: "Home Furniture",
                          })
                        }
                        value="Bathroom Furniture"
                        id="Furniture-Bathroom-Furniture"
                      />
                      <label
                        class="form-check-label"
                        for="Furniture-Bathroom-Furniture"
                      >
                        Bathroom Furniture
                      </label>
                    </div>
                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({
                            e,
                            cat: "Home Furniture",
                          })
                        }
                        value="Kids Room Furniture"
                        id="Furniture-Kids-Room"
                      />
                      <label class="form-check-label" for="Furniture-Kids-Room">
                        Kids Room Furniture
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({
                            e,
                            cat: "Home Furniture",
                          })
                        }
                        value="Home Office / Study Furniture"
                        id="Furniture-Home-Office"
                      />
                      <label
                        class="form-check-label"
                        for="Furniture-Home-Office"
                      >
                        Home Office / Study Furniture{" "}
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({
                            e,
                            cat: "Home Furniture",
                          })
                        }
                        value="Garden Furniture"
                        id="Furniture-Garden"
                      />
                      <label class="form-check-label" for="Furniture-Garden">
                        Garden Furniture
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({
                            e,
                            cat: "Home Furniture",
                          })
                        }
                        value="Outdoor Furniture"
                        id="Furniture-Outdoor"
                      />
                      <label class="form-check-label" for="Furniture-Outdoor">
                        Outdoor Furniture
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({
                            e,
                            cat: "Home Furniture",
                          })
                        }
                        value="Accent Furniture"
                        id="Furniture-Accent"
                      />
                      <label class="form-check-label" for="Furniture-Accent">
                        Accent Furniture
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({
                            e,
                            cat: "Home Furniture",
                          })
                        }
                        value="Small Furniture"
                        id="Furniture-Small"
                      />
                      <label class="form-check-label" for="Furniture-Small">
                        Small Furniture
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p className="fw-bold">Home Textiles</p>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({
                            e,
                            cat: "Home Textiles",
                          })
                        }
                        value="Bed Linen"
                        id="Textiles-Bed"
                      />
                      <label class="form-check-label" for="Textiles-Bed">
                        Bed Linen
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({
                            e,
                            cat: "Home Textiles",
                          })
                        }
                        value="Bath Linen"
                        id="Textiles-Bath"
                      />
                      <label class="form-check-label" for="Textiles-Bath">
                        Bath Linen
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({
                            e,
                            cat: "Home Textiles",
                          })
                        }
                        value="Curtains"
                        id="Textiles-Curtains"
                      />
                      <label class="form-check-label" for="Textiles-Curtains">
                        Curtains
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({
                            e,
                            cat: "Home Textiles",
                          })
                        }
                        value="Rugs & Carpets"
                        id="Textiles-Carpets"
                      />
                      <label class="form-check-label" for="Textiles-Carpets">
                        Rugs & Carpets
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({
                            e,
                            cat: "Home Textiles",
                          })
                        }
                        value="Kitchen & Table"
                        id="Textiles-Kitchen"
                      />
                      <label class="form-check-label" for="Textiles-Kitchen">
                        Kitchen & Table
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({
                            e,
                            cat: "Home Textiles",
                          })
                        }
                        value="Furnishing"
                        id="Textiles-Furnishing"
                      />
                      <label class="form-check-label" for="Textiles-Furnishing">
                        Furnishing
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({
                            e,
                            cat: "Home Textiles",
                          })
                        }
                        value="Decorative Made-ups"
                        id="Textiles-Decorative"
                      />
                      <label class="form-check-label" for="Textiles-Decorative">
                        Decorative Made-ups
                      </label>
                    </div>

                    <div class="form-check" style={{ fontSize: "12px" }}>
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        onChange={(e) =>
                          this.feedproductofinterest({
                            e,
                            cat: "Home Textiles",
                          })
                        }
                        value="Mattresses"
                        id="Textiles-Mattresses"
                      />
                      <label class="form-check-label" for="Textiles-Mattresses">
                        Mattresses
                      </label>
                    </div>
                  </div>

                  <div class=" col-md-4 mb-3">
                    <label for="formGroupExampleInput" class="form-label mb-0">
                      Others{" "}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="formGroupExampleInput"
                      placeholder="Please Specify..."
                      value={this.state.otherinterestproducts}
                      onChange={(text) => {
                        this.setState({
                          otherinterestproducts: text.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="col-12 d-flex justify-content-end">
                  <button
                    className=" col-auto btn btn-danger download me-3"
                    style={{ background: "#BC945E" }}
                    onClick={() => document.querySelector("#step4-tab").click()}
                  >
                    Previous{" "}
                  </button>
                  <button
                    className=" col-auto btn btn-danger download"
                    style={{ background: "#BC945E" }}
                    onClick={() => {
                      if (
                        this.state.productinterestedin.length > 0 ||
                        this.state.otherinterestproducts != ""
                      ) {
                        document.querySelector("#step6-tab").click();
                      } else {
                        Notiflix.Notify.failure("Please Select 1 product");
                      }
                    }}
                  >
                    Next{" "}
                  </button>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="Step6"
              role="tabpanel"
              aria-labelledby="step6-tab"
            >
              <div className="row m-0 m-2 border p-3 rounded-3">
                <div
                  className="row m-0 py-2 rounded-2 text-white mb-3"
                  style={{ backgroundColor: "#BC945E" }}
                >
                  <h4 className="col-auto">Step 6 - Visitor's Registration</h4>
                </div>

                <div
                  className="mt-3"
                  style={{ fontSize: "13px", lineHeight: "18px" }}
                >
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        acknowledgeTick: e.target.checked ? true : false,
                      })
                    }
                  />{" "}
                  <span className="text-danger">* </span>I have read and accept{" "}
                  <span>Visitor terms and Conditions</span> &{" "}
                  <span>Privacy Policy </span>
                  on this website and agree to register as visitor to India
                  Furniture Expo 2025. By Submitting my registration, I give my
                  consent to India Furniture Expo 2025 to verify the provided
                  information submitted by me online by Email and also to send
                  me promotional information via digital and offline channels
                  not limited to email, SMS, WhatsApp, print newsletter and
                  invitation card.
                </div>

                <div className="mt-4">
                  <h5 className="text-danger">Important Notes:</h5>
                  <ul style={{ fontSize: "14px" }}>
                    <li>
                      1. Incomplete form in any respect will not be accepted.
                    </li>
                    <li>
                      2. India Furniture Expo is strictly a Trade Show.
                      Therefore, entry to consumers is not allowed.
                    </li>

                    <li>3. Children below 15 years are not allowed.</li>
                    <li>
                      4. Visitor badge is only for the personal use to the
                      issuer and cannot be transferred to another person even
                      within the same company or outside. In case found, the
                      badge will be confiscated by organisers staff or visitor
                      registration team.
                    </li>
                    <li>
                      5. Visitor may be banned permanently from entering India
                      Furniture Expo for such unauthorised transfer of badge to
                      another person.
                    </li>
                    <li>
                      6. Visitor badge is property of the organisers and can be
                      withdrawn at any time if they feel appropriate.
                    </li>
                  </ul>
                </div>

                <div
                  className="mt-3 border border-warning"
                  style={{ backgroundColor: "#fff2cc" }}
                >
                  <p className="text-danger" style={{ fontSize: "12px" }}>
                    Note:
                  </p>
                  <p style={{ lineHeight: "10px", fontSize: "12px" }}>
                    If you find difficulty in doing your visitor registration,
                  </p>
                  <p style={{ fontSize: "12px" }}>
                    For assistance or queries, please contact Ms. Soniya Pawar
                    on Mob :{" "}
                    <a className="text-danger" href="tel: 9359992938">
                      +91 93599 92938
                    </a>{" "}
                    or email :{" "}
                    <a href="mailto: jivesh@infairs.in" className="text-danger">
                      jivesh@infairs.co.in
                    </a>
                  </p>
                </div>

                <div className="row  m-0 mt-3 justify-content-end">
                  <button
                    className=" col-auto btn btn-danger download me-3"
                    style={{ background: "#BC945E" }}
                    onClick={() => document.querySelector("#step5-tab").click()}
                  >
                    Previous{" "}
                  </button>

                  <button
                    className="btn btn-danger download col-auto"
                    style={{ background: "#BC945E" }}
                    onClick={() => {
                      if (this.state.acknowledgeTick) {
                        this.addVisitor();
                      } else {
                        Notiflix.Notify.failure(
                          "Please Tick the Visitor's Terms & Conditions."
                        );
                      }
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
