
import React from 'react';
import Category from '../Comp/Category';
import Footer from '../Comp/Footer'
import Header from '../Comp/Header';
import Video from '../assets/video/alfresco.mp4'
import ReactPlayer from 'react-player/lazy';
import { Helmet } from 'react-helmet';

const Alfresco = () => {
    return (
        <>
            <Helmet>
                <title>Outdoor Furniture | Exhibition Organizer | Hide India Expo 2025 </title>
                <meta name="description"
                    content="Hide India Expo 2025 has created an alfresco Zone that will be overflowing with only the trendiest and most innovative outdoor dining furniture and fittings on the planet." />
                <meta name="keywords"
                    content="Hospitality Interior & Design Expo 2025,Book Your Exhibition Stall, Exhibition Stall  Creative Directors , Business Development Directors, Architects, Heads of Marketing , Graphic Designers , Brand Manager , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions" />

                {/* Og Tags */}
                <meta property="og:url" content="https://hideindia.com/alfresco-zone" />
                <meta property="og:title" content="Outdoor Furniture | Exhibition Organizer | Hide India Expo 2025 " />
                <meta property="og:description" content="Hide India Expo 2025 has created an alfresco Zone that will be overflowing with only the trendiest and most innovative outdoor dining furniture and fittings on the planet." />
                <meta name="og:site_name" content="Hospitality Interior & Design Expo 2025" />
                <meta property="og:image" content=" https://hideindia.com/assets/images/inner-banner/al_fresco_zone.webp" />

                {/* Twiter Tags */}
                <meta property="twitter:url" content="https://hideindia.com/alfresco-zone" />
                <meta property="twitter:title" content="Outdoor Furniture | Exhibition Organizer | Hide India Expo 2025 " />
                <meta property="twitter:description" content="Hide India Expo 2025 has created an alfresco Zone that will be overflowing with only the trendiest and most innovative outdoor dining furniture and fittings on the planet." />
                <meta name="twitter:keywords"
                    content="Hospitality Interior & Design Expo 2025,Book Your Exhibition Stall, Exhibition Stall  Creative Directors , Business Development Directors, Architects, Heads of Marketing , Graphic Designers , Brand Manager , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions " />
                <meta property="twitter:image" content=" https://hideindia.com/assets/images/inner-banner/al_fresco_zone.webp" />
                <link rel="canonical" href="https://hideindia.com/alfresco-zone" />
            </Helmet>

            <Header />
            <section class="slider">
                <div class="container-fluid p-0">
                    <img src='/assets/images/inner-banner/al_fresco_zone.webp' alt='' class="bannerimg2 forwebsite" />
                    <img src='/assets/images/banner/mobilebanner/alfrescozone.png.webp' alt='' class="bannerimg2 formobile" />
                    <div class="content">
                        <div class="bannertext" id="banner_text_inner">
                            <h1>AL Fresco Zone</h1>

                            <p class="bannerp">Dedicated to the companies whose stylish outdoor
                                furniture
                                and fittings <br />are revolutionising the alfresco experience. Al
                                Fresco Zone will exhibit the <br /> latest trends and designs of Outdoor
                                Sitting,
                                Dining, Lounging , for the <br /> Hospitality
                                industry at HIDE INDIA 2025
                            </p>
                        </div>

                    </div>
                </div>
            </section>



            <Category />


            <section class="furnituresection">
                <div class="container-fluid">
                    <div class="title">
                        <h1 className='mt-2'>Al Fresco Zone</h1>
                    </div>
                    <div class="row">
                        <div class="col-md-7">
                            <p className='mt-2'>
                                Dedicated to the companies whose stylish outdoor furniture and fittings are revolutionising the
                                alfresco experience.<br />
                                While it’s natural for many establishments to focus on and prioritise the look and feel of their
                                inside space, the
                                appearance and comfort offered on the outside is equally as vital to the overall customer
                                experience. A business with a
                                visually attractive outdoor space will appeal to more potential customers and create a strong
                                first impression in their
                                mind; while the rising popularity of alfresco experiences across the globe means it’s never been
                                more important to make
                                the most of any outdoor space.<br />
                                That’s why the Hospitality Interior & Design Expo 2025 has created an Alfresco Zone that will be
                                overflowing with only
                                the trendiest and most innovative outdoor dining furniture and fittings on the planet. Housing
                                everything from the very
                                latest heating solutions to the most stylish all-weather seating, you will discover the products
                                to transform an
                                alfresco experience under the sun or stars.<br />
                                <b><i>Al Fresco Zone</i></b> will exhibit the latest trends and designs of Outdoor Dining
                                Furniture for the Hospitality industry at
                                <b>HIDE 2025</b>
                            </p>
                        </div>
                        <div class="col-md-5">

                            <ReactPlayer
                                url={Video}
                                playing
                                loop={true}
                                autoplay
                                width="100%"
                                height="100%"
                            />

                        </div>
                    </div>
                </div>
            </section>










            <Footer />

        </>
    )
}

export default Alfresco