import React, { useState } from "react";
import { Input } from "antd";

function OTPInput({ length = 4, onComplete }) {
  const [otp, setOtp] = useState(Array(length).fill(""));

  // Handle input change
  const handleChange = (value, index) => {
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Automatically focus on the next input field
      if (value && index < length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }

      // Trigger onComplete when all fields are filled
      if (newOtp.join("").length === length) {
        onComplete && onComplete(newOtp.join(""));
      }
    }
  };

  // Handle keydown for backspace functionality
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      if (!otp[index]) {
        if (index > 0) {
          // Move focus to the previous input and clear it
          document.getElementById(`otp-input-${index - 1}`).focus();
          newOtp[index - 1] = "";
          setOtp(newOtp);
        }
      } else {
        // Clear the current input
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {otp.map((digit, index) => (
        <Input
          key={index}
          id={`otp-input-${index}`}
          type="text"
          maxLength={1} // Only one digit per input
          value={digit}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)} // Handle backspace
          style={{ width: "50px", textAlign: "center", fontSize: "16px" }}
          inputMode="numeric" // Ensure numeric input on mobile
        />
      ))}
    </div>
  );
}

export default OTPInput;
