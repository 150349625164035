import React, { Component } from "react";
import Footer from "../../Comp/Footer";
import Header from "../../Comp/Header";
import Notiflix from "notiflix";
import PostApiCall from "../../API/POSTAPI";
import { Helmet } from "react-helmet";
export default class SeminarRegistrationComing extends Component {

  render() {
    return (
      <>
        <Helmet>
          <title>
            Seminar Registration | Hide India | Hospitality Interior & Design
            Expo 2025
          </title>
          <meta
            name="description"
            content="People who can visit our Expo - Hospitality Business Owners, Interior Designers, Heads of Design, Creative Directors, Brand Managers, Heads of Marketing, Graphic Designers, Directors, CEO’s, Business Development Directors, Architects"
          />
        </Helmet>
        <Header />

        <section class="slider ">
          <div class="container-fluid p-0">
            <img
              src="/assets/images/inner-banner/seminarragistrationweb.webp"
              alt=""
              class="bannerimg2 forwebsite"
            />
            <img
              src="/assets/images/banner/mobilebanner/seminarragistrationphn.webp"
              alt=""
              class="bannerimg2 formobile"
            />
            <div class="content">
              <div class="bannertext"></div>
            </div>
          </div>
        </section>

        <div className="container-fluid my-5">
          <div className="row">
            <div
              className="row m-0 mainparentseminar"
            >
              <h4 className="col-auto seminarvisitorheading w-100 py-2 rounded-2 text-white mb-4" style={{ backgroundColor: "#f7904c" }}>
                Seminar Registration
              </h4>
            </div>
            <div className="col-12 text-center">
              <h2>Coming Soon</h2>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}
