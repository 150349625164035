import React, { Component } from "react";
import Category from "../../Comp/Category";
import Footer from "../../Comp/Footer";
import Header from "../../Comp/Header";

import PostApiCall from "../../API/POSTAPI";
import GetApiCall from "../../API/GETAPI";
import Notiflix from "notiflix";
import { Helmet } from 'react-helmet'

export default class ExhibitorRegistration extends Component {
  constructor() {
    super();

    this.state = {
      EmailRegex:
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      NumRegex: /^[0-9]*$/,
      exhid: null,
      type: null,
      boothType: null,
      bootharea: "",
      products: [],
      otherproduct: "",
      contactfirstname: "",
      contactlastname: "",
      contactdesignation: "",
      Phonecode: "",
      contactmobile: "",
      contactmail: "",
      contactaddressline1: "",
      contactaddressline2: "",
      contactpincode: "",
      contactcity: null,
      contactState: null,
      contactCountry: null,
      company: "",
      contactwebsite: "",

      BrandArray: [{ brand: "", product: "", country: "" }],
      userid: null,
      emailid: null,

      participateType: null,

      brands: "",
      agentName: null,
      agentmail: null,

      openingType: null,

      title: null,
      firstname: null,
      lastname: null,
      designation: null,
      mobile: "",
      organisationmail: null,

      contacttitle: null,

      contactwhatsapp: "",

      contacttelSTDcode: "",
      contacttelnumber: "",
      contactfaxSTDcode: "",
      contactfaxnumber: "",

      contactPersonParentGroup: null,

      CountryData: [],
      CountryId: null,

      StateData: [],
      StateId: null,

      CityData: [],
      CityId: null,
      Loader: false
    };
  }

  componentDidMount() {
    GetApiCall.getRequest("Get_Country").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          // console.log(obj.data)
          this.setState({ CountryData: obj.data });
        }
      })
    );
  }

  onCountryChange() {
    PostApiCall.postRequest(
      {
        countryid: this.state.CountryId,
      },
      "Get_State"
    ).then((results) =>
      results.json().then((objstate) => {
        if (results.status == 200 || results.status == 201) {
          if (objstate.data.length != 0) {
            this.setState({ StateData: objstate.data });
          }
        }
      })
    );
  }

  onStateChange() {
    PostApiCall.postRequest(
      {
        stateid: this.state.StateId,
      },
      "Get_City"
    ).then((resultscity) =>
      resultscity.json().then((objcity) => {
        if (resultscity.status == 200 || resultscity.status == 201) {
          if (objcity.data.length != 0) {
            this.setState({
              CityId: objcity.data[0].value,
              CityData: objcity.data,
              City: objcity.data[0].label,
            });
          }
        }
      })
    );
  }

  feedproducts(e) {
    if (e.target.checked) {
      this.setState({ products: [...this.state.products, e.target.value] });
    } else {
      this.setState({
        products: this.state.products.filter((val) => val != e.target.value),
      });
    }
  }

  validOrgHead() {
    if (this.state.company != "") {
      if (this.state.contactfirstname != "") {
        if (this.state.contactmobile != "") {
          if (this.state.contactmobile.length == 10) {
            if (this.state.contactmail != "") {
              if (this.state.EmailRegex.test(this.state.contactmail)) {
                if (this.state.contactwebsite != "") {
                  this.saveExhibitor();
                } else {
                  Notiflix.Notify.failure("Please Enter Location ");
                }
              }

              else {
                Notiflix.Notify.failure("Please Enter Valid Email ");
              }
            } else {
              Notiflix.Notify.failure("Please Enter Email ");
            }
          } else {
            Notiflix.Notify.failure("Please Enter Valid Mobile Number");
          }
        } else {
          Notiflix.Notify.failure("Please Enter Your Contact Number ");
        }
      }
      else {
        Notiflix.Notify.failure("Please Enter Your Name ");
      }
    }
    else {
      Notiflix.Notify.failure("Please Enter Company Name ");
    }
  }

  saveExhibitor() {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        exhid: this.state.exhid,
        clientfrom: this.state.type,
        participation: null,
        agentname: null,
        agentemail: null,
        companyname: this.state.company,
        approxarea: this.state.bootharea,
        boothType: this.state.boothType,
        brands:
          this.state.products.length != 0
            ? this.state.products.toString()
            : this.state.otherproduct,
        orgheadtitle: null,
        orgheadfirstname: null,
        orgheadlastname: null,
        designation: null,
        mobile: null,
        emailid: null,

        countrycode: this.state.Phonecode,

        contactpersontitle: this.state.contacttitle,
        contactpersonfirstname: this.state.contactfirstname,
        contactpersonlastname: this.state.contactlastname,
        contactpersondesignation: this.state.contactdesignation,
        contactpersonmobnumber: this.state.contactmobile,
        contactpersonwhatsapp: this.state.contactwhatsapp,
        contactpersonemailid: this.state.contactmail,
        contactpersoncountry: this.state.contactCountry,
        contactpersonaddress1: this.state.contactaddressline1,
        contactpersonaddress2: this.state.contactaddressline2,
        contactpersonaddress3: this.state.contactaddressline3,
        contactpersonpincode: this.state.contactpincode,
        contactpersoncity: this.state.contactcity,
        contactpersonstate: this.state.contactState,
        contactpersontelno: this.state.contacttelnumber,
        contactpersonfaxno: this.state.contactfaxnumber,
        contactpersonwebsite: this.state.contactwebsite,
        contactpersonparentgroup: this.state.contactPersonParentGroup,
      },
      "Update_Exhibitor"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          PostApiCall.postRequest(
            {
              email: this.state.contactmail,
              name: `${this.state.contactfirstname} ${this.state.contactlastname}`,
            },
            "Send_Ticket_Exhibitor_Mailer"
          ).then((results) =>
            results.json().then((obj) => {
              if (results.status == 200 || results.status == 201) {
                PostApiCall.postRequest(
                  {
                    Participation:
                      this.state.participateType == null
                        ? "Empty Field"
                        : this.state.participateType,
                    BoothType:
                      this.state.boothType == null
                        ? "Empty Field"
                        : this.state.boothType,
                    BoothArea:
                      this.state.bootharea == ""
                        ? "Empty Field"
                        : this.state.bootharea,
                    Products:
                      this.state.products.length != 0
                        ? this.state.products.toString()
                        : this.state.otherproduct,
                    FullName: `${this.state.contactfirstname} ${this.state.contactlastname}`,
                    Designation: this.state.contactdesignation,
                    Mobile: this.state.contactmobile,
                    Email: this.state.contactmail,
                    Address: `${this.state.contactaddressline1} ${this.state.contactaddressline2}`,
                    Pincode: this.state.contactpincode,
                    City:
                      this.state.contactcity == null
                        ? "Empty Field"
                        : this.state.contactcity,
                    State:
                      this.state.contactState == null
                        ? "Empty Field"
                        : this.state.contactState,
                    Country:
                      this.state.contactCountry == null
                        ? "Empty Field"
                        : this.state.contactCountry,
                    CompanyName:
                      this.state.company == ""
                        ? "Empty Field"
                        : this.state.company,
                    CompanyWebsite:
                      this.state.contactwebsite == ""
                        ? "Empty Field"
                        : this.state.contactwebsite,
                  },
                  "Send_Exhibitor_Form_Mailer"
                ).then((results) =>
                  results.json().then((obj) => {
                    if (results.status == 200 || results.status == 201) {                      
                      Notiflix.Loading.remove();
                      Notiflix.Notify.success(
                        "Thank you for Submitting your Exhibitor Details Successfully. We will Shortly get back to you."
                      );
                      window.location.href = "/";
                    } else {
                      Notiflix.Loading.remove();
                      Notiflix.Notify.failure(obj.data);
                    }
                  })
                );

                Notiflix.Loading.remove();
                Notiflix.Notify.success(
                  "Thank you for Submitting your Exhibitor Details Successfully. We will Shortly get back to you."
                );
                // window.location.href = '/'
              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(obj.data);
              }
            })
          );
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Book Your Stall | Hide India | Hospitality Interior & Design Expo 2025 </title>
          <meta name="description"
            content="Book Your Exhibition Stall with Hospitality Interior and Design 2025. India's leading event for the designs behind business growth! Hide India 2025" />
          <meta name="keywords"
            content="Hospitality Interior & Design Expo 2025,Book Your Exhibition Stall, Exhibition Stall  Creative Directors , Business Development Directors, Architects, Heads of Marketing , Graphic Designers , Brand Manager , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions" />

          {/* Og Tags */}
          <meta property="og:url" content="https://hideindia.com/exhibitor-registration" />
          <meta property="og:title" content="Book Your Stall | Hide India | Hospitality Interior & Design Expo 2025 " />
          <meta property="og:description" content="Book Your Exhibition Stall with Hospitality Interior and Design 2025. India's leading event for the designs behind business growth! Hide India 2025" />
          <meta name="og:site_name" content="Hospitality Interior & Design Expo 2025" />
          <meta property="og:image" content="https://hideindia.com/assets/images/banner/aboutus.png" />

          {/* Twiter Tags */}
          <meta property="twitter:url" content="https://hideindia.com/exhibitor-registration" />
          <meta property="twitter:title" content="Book Your Stall | Hide India | Hospitality Interior & Design Expo 2025 " />
          <meta property="twitter:description" content="Book Your Exhibition Stall with Hospitality Interior and Design 2025. India's leading event for the designs behind business growth! Hide India 2025" />
          <meta name="twitter:keywords"
            content="Hospitality Interior & Design Expo 2025,Book Your Exhibition Stall, Exhibition Stall  Creative Directors , Business Development Directors, Architects, Heads of Marketing , Graphic Designers , Brand Manager , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions " />
          <meta property="twitter:image" content="https://hideindia.com/assets/images/banner/aboutus.png" />
          <link rel="canonical" href="https://hideindia.com/exhibitor-registration" />
        </Helmet>
        <Header />

        <section class="slider ">
          <div class="container-fluid p-0">
            <img
              src="/assets/images/banner/aboutus.png"
              alt=""
              class="bannerimg2 forwebsite"
            />
            <img
              src="/assets/images/banner/mobilebanner/aboutus.png.webp"
              alt=""
              class="bannerimg2 formobile"
            />
            <div class="content">
              <div class="bannertext"></div>
            </div>
          </div>
        </section>

        <div className="container-fluid ps-lg-3">
          <div class="title">
            {" "}
            <h1 className="mt-5 ms-2">Book Your Stall</h1>
          </div>
          <p className="mt-4 ms-2 ">
            If you are interested in exhibiting at HIDE India 2025, please fill out the form given below.
          </p>

          <div className=" row m-0 mt-2">
            <div class=" col-md-4 col-12 mb-3 ">
              <label for="formGroupExampleInput" class="form-label mb-0">
                Company Name{" "}
              </label>
              <input
                type="text"
                class="form-control py-2"
                id="formGroupExampleInput"
                value={this.state.company}
                onChange={(text) => {
                  this.setState({ company: text.target.value });
                }}
              />
            </div>

            <div class=" col-md-4 col-12 mb-3 ">
              <label for="formGroupExampleInput" class="form-label mb-0">
                Contact Person <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                class="form-control py-2"
                id="formGroupExampleInput"
                value={this.state.contactfirstname}
                onChange={(text) => {
                  this.setState({
                    contactfirstname: text.target.value,
                  });
                }}
              />
            </div>

            <div class=" col-md-4 col-12 mb-3">
              <label for="formGroupExampleInput" class="form-label mb-0">
                Mobile Number <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                class="form-control py-2"
                value={this.state.contactmobile}
                id="formGroupExampleInput"
                maxLength={10}
                onChange={(mobile) => {
                  if (this.state.NumRegex.test(mobile.target.value)) {
                    this.setState({ contactmobile: mobile.target.value });
                  }
                }}
              />
            </div>

            <div class=" col-md-6 col-12 mb-3 ">
              <label for="formGroupExampleInput" class="form-label mb-0">
                Email ID <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="text"
                class="form-control py-2"
                id="formGroupExampleInput"
                value={this.state.contactmail}
                onChange={(text) => {
                  this.setState({ contactmail: text.target.value });
                }}
              />
            </div>


            <div class=" col-md-6 col-12 mb-3 ">
              <label for="formGroupExampleInput" class="form-label mb-0">
                Location
              </label>
              <input
                type="text"
                class="form-control py-2"
                id="formGroupExampleInput"
                value={this.state.contactwebsite}
                onChange={(text) => {
                  this.setState({ contactwebsite: text.target.value });
                }}
              />
            </div>
          </div>

          <div className="my-3" style={{ padding: '0px 15px' }}>
            <h4
              className="text-danger mt-2"
              style={{ fontWeight: "400", fontSize: "18px" }}
            >
              NOTE:
            </h4>

            <p>
              <span className="text-danger">*</span> These fields are compulsory
              to fill.
            </p>

            <p style={{ lineHeight: "18px" }}>
              {" "}
              Please note that this is only an Exhibitor Request Form and not
              Exhibitor Contract Form. Our team will contact you immediately
              with further details once you fill in this request form.
            </p>

            <button
              className=" mt-3 btn btn-danger download"
              onClick={
                this.validOrgHead.bind(this)               
              }
            >
              Submit
            </button>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
