
import React from 'react';
import { Helmet } from 'react-helmet';
import Gallerimg1 from '../assets/image/1.jpg'
import Gallerimg2 from '../assets/image/2.jpg'
import Gallerimg3 from '../assets/image/3.jpg'
import Gallerimg4 from '../assets/image/4.jpg'
import Gallerimg5 from '../assets/image/5.jpg'
import Gallerimg6 from '../assets/image/6.jpg'
import Gallerimg7 from '../assets/image/7.jpg'
import Gallerimg8 from '../assets/image/8.jpg'
import Gallerimg9 from '../assets/image/9.jpg'
import Gallerimg10 from '../assets/image/10.jpg'
import Gallerimg11 from '../assets/image/11.jpg'
import Gallerimg12 from '../assets/image/12.jpg'
import Gallerimg13 from '../assets/image/13.jpg'
import Gallerimg14 from '../assets/image/14.jpg'
import Gallerimg15 from '../assets/image/15.jpg'
import Gallerimg16 from '../assets/image/16.jpg'
import Gallerimg17 from '../assets/image/17.jpg'
import Gallerimg18 from '../assets/image/18.jpg'
import Gallerimg19 from '../assets/image/19.jpg'
import Gallerimg20 from '../assets/image/20.jpg'
import Gallerimg21 from '../assets/image/21.jpg'
import Gallerimg22 from '../assets/image/22.jpg'
import Gallerimg23 from '../assets/image/23.jpg'
import Gallerimg24 from '../assets/image/24.jpg'
import Gallerimg25 from '../assets/image/25.jpg'
import Gallerimg26 from '../assets/image/26.jpg'
import Gallerimg27 from '../assets/image/27.jpg'
import Gallerimg28 from '../assets/image/28.jpg'
import Gallerimg29 from '../assets/image/29.jpg'
import Gallerimg30 from '../assets/image/30.jpg'
import Gallerimg31 from '../assets/image/31.jpg'
import Gallerimg32 from '../assets/image/32.jpg'
import Gallerimg33 from '../assets/image/33.jpg'
import Gallerimg34 from '../assets/image/34.jpg'
import Gallerimg35 from '../assets/image/35.jpg'
import Gallerimg36 from '../assets/image/36.jpg'
import Gallerimg37 from '../assets/image/37.jpg'
import Gallerimg38 from '../assets/image/38.jpeg'
import Gallerimg39 from '../assets/image/39.jpeg'
import Gallerimg40 from '../assets/image/40.jpg'
import Gallerimg41 from '../assets/image/41.jpg'
import Gallerimg42 from '../assets/image/42.jpg'
import Banner1 from '../assets/banner/banner.jpg'


import './Gallery.css';
import Footer from './Footer'
import Header from './Header'


const Gallery = () => {
    return (
        <>
            <Helmet>
                <title>Goa Edition | Gallery | Hide India Expo 2025</title>
                <meta name="description"
                    content="Exhibition Gallery of Goa Edition with multiple Hospitality Interior Design Stall By Hide India Expo." />
                <meta name="keywords"
                    content="Hospitality Interior & Design Expo 2025, Goa Edition , Gallery of Goa Edition , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions" />

                {/* Og Tags */}
                <meta property="og:url" content="https://hideindia.com/gallery" />
                <meta property="og:title" content="Goa Edition | Gallery | Hide India Expo 2025" />
                <meta property="og:description" content="Exhibition Gallery of Goa Edition with multiple Hospitality Interior Design Stall By Hide India Expo." />
                <meta name="og:site_name" content="Hospitality Interior & Design Expo 2025" />
                <meta property="og:image" content="https://hideindia.com/static/media/banner.8f03bebabd819f34df2b.jpg" />

                {/* Twiter Tags */}
                <meta property="twitter:url" content="https://hideindia.com/gallery" />
                <meta property="twitter:title" content="Goa Edition | Gallery | Hide India Expo 2025" />
                <meta property="twitter:description" content="Exhibition Gallery of Goa Edition with multiple Hospitality Interior Design Stall By Hide India Expo." />
                <meta name="twitter:keywords"
                    content="Hospitality Interior & Design Expo 2025, Goa Edition , Gallery of Goa Edition , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions " />
                <meta property="twitter:image" content="https://hideindia.com/static/media/banner.8f03bebabd819f34df2b.jpg" />
                <link rel="canonical" href="https://hideindia.com/gallery" />
            </Helmet>


            <Header />
            <section class="slider">
                <div class="container-fluid p-0">
                    <img src={Banner1} alt='' class="bannerimg2 forwebsite" />
                    {/* <img src='/assets/images/banner/mobilebanner/banner.jpg' alt='' class="bannerimg2 formobile" /> */}
                    <div class="content">
                        <div class="bannertext">

                        </div>
                    </div>
                </div>
            </section>
            <div class="container-fluid mt-5">
                <div class="title">
                    <h1 style={{ marginBottom: "10px" }} >Goa Edition</h1>

                </div>
            </div>
            <div className='gallery'>
                {/* <div className='heading'>
                <p>Gallery</p>
            </div> */}
                <div className='photo '>
                <div className='zoom'>
                        <img src={Gallerimg38} ></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg39} ></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg40} ></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg41} ></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg42} ></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg1} ></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg2} ></img>
                    </div>

                    <div className='zoom'>
                        <img src={Gallerimg3} ></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg4} ></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg5}></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg7}></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg8}></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg9}></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg10}></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg11}></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg12}></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg13}></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg14}></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg15}></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg16}></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg17}></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg18}></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg19}></img>
                    </div>

                    <div className='zoom'>
                        <img src={Gallerimg20}></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg21}></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg22}></img>
                    </div>

                    <div className='zoom'>
                        <img src={Gallerimg23}></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg24}></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg25}></img>
                    </div>

                    <div className='zoom'>
                        <img src={Gallerimg27}></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg28}></img>
                    </div>
                    <div className='zoom'>
                        <img src={Gallerimg29}></img>
                    </div>




                    <div className='zoom'>  <img src={Gallerimg30}></img></div>
                    <div className='zoom'>  <img src={Gallerimg31}></img></div>
                    <div className='zoom'>  <img src={Gallerimg32}></img></div>
                    <div className='zoom'> <img src={Gallerimg33}></img></div>
                    <div className='zoom'>  <img src={Gallerimg34}></img></div>
                    <div className='zoom'>  <img src={Gallerimg35}></img></div>
                    <div className='zoom'>  <img src={Gallerimg36}></img></div>
                    <div className='zoom'>  <img src={Gallerimg37}></img></div>


                </div>

            </div>
            <Footer />
        </>

    )
}

export default Gallery


