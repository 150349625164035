import QRCode from 'qrcode';
import { useState } from 'react';
import logo from "../assets/banner/hidelogo.png";
import "./Showdirectory.css";
export default function CatalogQrCode() {
    const [qrUrl, setQrUrl] = useState("");
    var opts = {
        errorCorrectionLevel: 'H',
        type: 'image/jpeg',
        quality: 0.6,
        margin: 1,
        width: 3000
    }
    QRCode.toDataURL(`https://hideindia.com/previewcatalogue`, opts).then((url) => {
        setQrUrl(url);
    })
    return (
        <>
            <div className="d-flex w-100 justify-content-center bg-white">
                <img
                    //   className="col-11"
                    src={logo}
                    width="87%"
                    style={{ borderRadius: "7px", marginTop: "10px" }}
                />
            </div>
            <img src={qrUrl} className="img-fluid" />
        </>
    );
}
